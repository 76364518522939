import React, {useEffect, useRef} from 'react';
import type {WdiModalAppProps} from '@/components/WdiControls';
import {FieldProps} from "@/components/WdiControls";
import type {ProFormInstance} from '@ant-design/pro-form';
import {EC} from '@/services/mdm/ec';
import {WdiSelect, WdiTreeSelect} from '@/components/WdiControls/WdiSelect';
import {Form, Input, message} from 'antd';
import {WdiModalForm} from '@/components/WdiControls/WdiModalForm';
import {ResponseStatus} from '@/utils/request';
import {WdiInputNumber} from '@/components/WdiControls/WdiInputNumber';
import {DataOptions} from '@/services/basic/metadata';
import {FormSalesContract} from "@/services/contract/formsalescontract";
import {Enum} from "@/enums/system";
import {SoldTo} from "@/services/mdm/soldto";

const {TextArea} = Input;

const processModeDataLoading = (params: any) => {
  return DataOptions.search(Object.assign({typeGroup: 13}, params)).then((response) => {
    return response.data.map((item) => ({value: item.value, label: item.name}));
  });
};

const SalesContractTermsEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  useEffect(() => {
    formRef.current?.setFieldsValue({
      processMode: props?.data?.row?.processMode,
    });
    if (props.data.row) {
      const detail = props.data.row;
      formRef.current?.setFieldsValue({
        salesContractTermsId: detail.salesContractTermsId,
        ecId: detail.ecId,
        rateOriginal: detail.rateOriginal,
        remark: detail.remark,
      });
    }
  }, [props.data.row, props.type]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{span: 6}}
      wrapperCol={{span: 12}}
      labelAlign="left"
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      preserve={true}
      modalProps={{
        onCancel: () => {
          props.onClose ? props.onClose() : null;
        },
        destroyOnClose: true,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        const {...entity} = values;

        const response = await FormSalesContract.Buttons.validateDetail({
          rowIndex: props.data.rowIndex,
          formSalesContract: {
            pageTypeId: props.data?.pageTypeId,
            soldToCode: props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode,
            revenueForecast: props.data?.revenueForecast
          },
          formSalesContractTerms: entity,
          formSalesContractTermsList: props.data.rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
        } else {
          if (props.onSave) {
            props.onSave({
              code: ResponseStatus.SUCCESS,
              message: '',
              data: response.data,
            });
          }
        }
      }}
    >
      <Form.Item name="salesContractTermsId" label="salesContractTermsId" hidden>
        <Input/>
      </Form.Item>
      <Form.Item
        name="processMode"
        label="条款类型"
        rules={[{required: true, message: '条款类型不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择条款类型',
            disabled: true,
            dataLoading: processModeDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="ecId"
        label="费用项"
        rules={[{required: true, message: '费用项不能为空!'}]}
      >
        <WdiTreeSelect {...{
          placeholder: "请选择费用项",
          dataLoading: async (params) => {
            const soldto = await SoldTo.get(props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode);
            const response = await EC.searchEc3(Object.assign({
              toParentLevel: Enum.ECLevel.EC1,
              withPermissions: true,
              isActive: true,
              pageTypeIds: [props.data?.pageTypeId].join(),
              processModeId: props?.data?.row?.processMode,
              bohqId: soldto.data.defaultBohqId
            }, params));

            const {treeData, dict} = FieldProps.TreeSelect.formatTreeData({
              textField: "ecName",
              valueField: "ecId",
              id: "ecId",
              pId: "parentId",
              level: "ecLevel",
              disabledLevel: [Enum.ECLevel.EC1, Enum.ECLevel.EC2],
            }, response.data);

            return {treeData, dict};
          }
        }} />
      </Form.Item>
      <Form.Item
        required
        name="rateOriginal"
        label="初始比例(%)"
        rules={[{required: true, message: '初始比例不能为空!'}]}
      >
        <WdiInputNumber digits={4} min={0} format={"p"}/>
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <TextArea rows={3} style={{width: '100%'}}/>
      </Form.Item>
    </WdiModalForm>
  );
};

export default SalesContractTermsEditApp;
