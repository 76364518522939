import {formatter, request} from "@/utils/request";

export namespace FormSalesContractTerms {
  const Path = {
    ExistsRefDetail: '/contractapi/contract/existsRefDetail',
    ValidateDetail: '/contractapi/contract/validateDetail',
    GetFormSalesContractTerms: '/contractapi/formSalesContract/getFormSalesContractTerms',
  }

  export async function existsRefDetail(id: number) {
    const options = {
      method: 'GET',
      body: {
        formSalesContractId: id,
      },
    };
    return request<API.ResponseResult<Boolean>>(Path.ExistsRefDetail, formatter(options));
  }

  export async function validateDetail(body: API.FormSalesContractTermsPostParam) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.ResponseResult<API.FormSalesContractTermsVo>>(Path.ValidateDetail, formatter(options));
  }

  export async function getFormSalesContractTerms(formSalesContractId: number) {
    const options = {
      method: 'GET',
      body: {
        formSalesContractId: formSalesContractId,
      },
    };
    return request<API.ResponseResult<API.FormSalesContractTermsVo[]>>(Path.GetFormSalesContractTerms, formatter(options));
  }
}

