import DataStore from "@/components/GridWidget/DataStore";
import {WdiCardTitle, wdiCore, WdiModalAppProps} from "@/components/WdiControls";
import {WdiModal} from "@/components/WdiControls/WdiModalForm";
import {WdiPanel} from "@/components/WdiControls/WdiPanel";
import {WdiTable} from "@/components/WdiControls/WdiTable";
import {Enum} from "@/enums/system";
import {Button, TableColumnsType} from "antd";
import React, {useEffect, useState} from "react";
import {FormSalesContract} from "@/services/contract/formsalescontract";

const FormSalesContractTermsCompareModalApp: React.FC<WdiModalAppProps> = (props) => {
  const [addData, setAddData] = useState<API.FormSalesContractTermsAddCompare[]>([]);
  const [modifyData, setModifyData] = useState<API.FormSalesContractTermsModifyCompare[]>([]);
  const [loading, setLoading] = useState(false);
  const [basicInfo, setBasicInfo] = useState<string>();
  const [columns1, setColumns1] = useState<TableColumnsType<API.FormSalesContractTermsAddCompare>>([]);
  const [columns2, setColumns2] = useState<TableColumnsType<API.FormSalesContractTermsModifyCompare>>([]);
  const [isBasicChanged, setIsBasicChanged] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isModify, setIsModify] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const form = props.data?.form;
  const formSalesContract = props.data?.formSalesContract;

  const columnsGeneral1: TableColumnsType<API.FormSalesContractTermsAddCompare> = [
    {
      title: 'No.',
      width: 50,
      dataIndex: 'rowNumber',
      render(text, record, index) {
        return `${index + 1}`;
      }
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      ellipsis: true,
      width: 200,
    },
    {
      title: '核算类型',
      dataIndex: 'revenueTypeName',
      ellipsis: true,
      width: 200,
    },
    {
      title: '条款类型',
      dataIndex: 'processModeName',
      ellipsis: true,
      width: 150,
    },
    {
      title: '结案频率',
      dataIndex: 'settlementFrequencyName',
      ellipsis: true,
      width: 150,
    },
    {
      title: '返利坎级(ELN承担)',
      dataIndex: 'termsDesc',
      ellipsis: true,
      width: 200,
    },
    {
      title: 'ELN预估承担点数',
      dataIndex: 'rateForecast',
      width: 200,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.RateToString(value, 4)}</span>
      ),
    }
  ];
  const columnsGeneral2: TableColumnsType<API.FormSalesContractTermsModifyCompare> = [
    {
      title: 'No.',
      width: 50,
      dataIndex: 'rowNumber',
      render(text, record, index) {
        return `${index + 1}`;
      }
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      ellipsis: true,
      width: 200,
    },
    {
      title: '核算类型',
      dataIndex: 'revenueTypeName',
      ellipsis: true,
      width: 200,
    },
    {
      title: '条款类型',
      dataIndex: 'processModeName',
      ellipsis: true,
      width: 200,
    },
    {
      title: '修改内容',
      dataIndex: 'difference',
      ellipsis: true,
      width: 400,
    },
  ];

  useEffect(() => {
    if (form) {
      setLoading(true);
      FormSalesContract.Buttons.getFormSalesContractTermsCompareCollections({
        formId: form.formId,
        lastFormSalesContractId: formSalesContract.lastFormSalesContractId
      }).then((response) => {
        if (response && response.data) {
          setBasicInfo(response.data.basicInfo);
          setAddData(DataStore.init(response.data.add));
          setModifyData(DataStore.init(response.data.modify));
          if (!(response.data.basicInfo === undefined || response.data.basicInfo === "")) {
            setIsBasicChanged(true);
          }
          if (response.data.add && response.data.add.length > 0) {
            setIsAdd(true);
          }
          if (response.data.modify && response.data.modify.length > 0) {
            setIsModify(true);
          }
          setLoading(false);
          if ((response.data.basicInfo === undefined || response.data.basicInfo === "") &&
            !(response.data.add && response.data.add.length > 0) &&
            !(response.data.modify && response.data.modify.length > 0)) {
            setIsEmpty(true);
          }
        }
      });
      setColumns1(columnsGeneral1);
      setColumns2(columnsGeneral2);
    }
  }, [form]);

  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      width={1350}
      onCancel={() => {
        props.onClose?.();
      }}
      footer={[
        <Button key="back" onClick={() => {
          props.onClose?.();
        }}>
          关闭
        </Button>
      ]}
    >
      {isEmpty && (<span>无关键信息修改</span>)}
      {isBasicChanged && (<WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='基本信息变更'/>}>
        <span>{basicInfo}</span>
      </WdiPanel>)}
      {isAdd && (<WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='新增条款'/>}>
        <WdiTable
          showPageRows={10}
          columns={columns1}
          dataSource={addData}
          pagination={{showSizeChanger: false}}
          loading={loading}
        />
      </WdiPanel>)}
      {isModify && (<WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='条款变更'/>}>
        <WdiTable
          showPageRows={10}
          columns={columns2}
          dataSource={modifyData}
          pagination={{showSizeChanger: false}}
          loading={loading}
        />
      </WdiPanel>)}
    </WdiModal>
  );
};

export default FormSalesContractTermsCompareModalApp;
