import React, { ReactNode, useEffect, useState } from 'react';
import { Form, Row, Col, Button, FormInstance } from 'antd';

export type FilterColumn = {
  title: string;
  key?: string;
  dataIndex: string;
  span?: number;
  require?: boolean;
  initialValues?: any;
  render: (form: FormInstance<any>) => ReactNode;
};

export type ButtonColumn = {
  title: string;
  key?: string;
  doClick: (params: any, exParams?: any) => void;
};

export type GridFilterFormProps = {
  columns: FilterColumn[];
  buttonColumns?: ButtonColumn[];
  doSeach: (params: any, exParams?: any) => void;
  reLoadKey?: Number;
  // 额外参数，主要用于按钮查询获取
  params?: any;
};

type CellFilterColumn = {
  offset: number;
  span: number;
  key: string;
  column: FilterColumn;
}

const GridFilterForm: React.FC<GridFilterFormProps> = (props) => {
  const [form] = Form.useForm();
  const [columns, setColumns] = useState<CellFilterColumn[]>([]);
  const [buttonOffset, setButtonOffset] = useState(0);

  console.log(props);

  const formatRows = (columns: FilterColumn[]) => {
    const items: CellFilterColumn[] = [];
    let totalSpan = 0;
    const const_Total_Span = 24;
    (columns ? columns : []).forEach(function (column, itemIndex) {
      const span = column.span ? column.span : 4;
      if (totalSpan + span > const_Total_Span) {
        totalSpan = 0;
      }
      totalSpan += span;
      const key = column.key ? column.key : column.dataIndex;
      items.push({ column: column, offset: 0, span: span, key });
      if (column.initialValues) {
        form.setFieldsValue({
          [column.dataIndex]: column.initialValues
        })
      }
    });

    if (props?.buttonColumns && props?.buttonColumns.length > 1) {
      if (totalSpan + 8 > const_Total_Span) {
        totalSpan = 0;
      }
      setButtonOffset(const_Total_Span - 8 - totalSpan);
    } else {
      if (totalSpan + 4 > const_Total_Span) {
        totalSpan = 0;
      }
      setButtonOffset(const_Total_Span - 4 - totalSpan);
    }
    return items;
  };

  const onSearch = () => {
    form.validateFields().then(() => {
      props.doSeach(form.getFieldsValue(), props.params);
    });
  };
  useEffect(() => {
    setColumns(formatRows(props.columns));
  }, [props.columns])

  useEffect(() => { form.resetFields(); }, [props.reLoadKey]);

  return (
    <Form form={form}
      layout="vertical"
    >
      {
        (props.columns.length > 0) &&
        <Row gutter={[10, 0]}>
          {
            columns.map((row) => (
              <Col span={row.span} offset={row.offset} key={row.key}>
                {
                  <Form.Item
                    label={row.column.title}
                    name={row.column.dataIndex}
                    style={{ marginBottom: "5px" }}
                    rules={[{ required: row.column.require || false }]}
                  >
                    {row.column.render(form)}
                  </Form.Item>
                }
              </Col>
            ))
          }
          <Col span={2} offset={buttonOffset} key="_grid_button">
            <div style={{ marginBottom: "5px", float: 'right' }} >
              <Button style={(buttonOffset == 20 || (props?.buttonColumns && props?.buttonColumns.length > 1 && buttonOffset == 16)) ? {} : { bottom: '-30px' }} type="primary" onClick={onSearch}>搜索</Button>
            </div>
          </Col>
          {props?.buttonColumns && props?.buttonColumns.length > 0 &&
            props.buttonColumns.map((row) => (
              <Col span={2} key="_grid_button_1">
                <div style={{ marginBottom: "5px", float: 'right' }} >
                  <Button key={row.key} type="primary" onClick={() => {
                    form.validateFields().then(() => {
                      row.doClick(form.getFieldsValue(), props.params)
                    });
                  }}
                    style={(columns && columns.length > 5) ? { bottom: '-30px' } : {}}
                  >{row.title}</Button>
                </div>
              </Col>
            ))
          }
        </Row>
      }
    </Form >
  );
}

export { GridFilterForm };


