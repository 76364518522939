import type {ParamsProps} from '@/components/WdiBus/FormPage';
import type {WdiModalAppResult} from '@/components/WdiControls';
import {PageType} from "@/services/basic/pagetype";
import {SoldTo} from "@/services/mdm/soldto";
import {CustSys} from '@/services/mdm/custsys';
import {SalesContract} from '@/services/contract/salescontract';
import {FormSalesContract} from '@/services/contract/formsalescontract';
import {Enum} from "@/enums/system";
import {FormApi} from '@/services/sales';
import {ResponseCode} from "@/utils/response";
import {ResponseStatus} from '@/utils/request';
import {PageGroup} from "@/services/basic/pagegroup";
import {BOHQ} from "@/services/mdm/bohq";

export type ViewEntityProps = {
  salesContract?: API.SalesContract;
  form?: API.Form,
  formSalesContract?: API.FormSalesContract;
  formSalesContractId?: number;
  relationType?: number,
  relatedFormId?: number,
  formId?: number;
  statusId?: number;
  formTypeId?: number;
  pageTypeId?: number;
  bohqId?: number;
  createOrModify?: number,
  pageType?: API.PageType;
  custSys?: API.CustSys;
  soldTo?: API.SoldTo;
  bohq?: API.BOHQ;
  pageGroup?: API.PageGroup;
}

export const isDealerContract = (pageTypeId?: number) => {
  if (pageTypeId && (pageTypeId == Enum.PageType.DIRECT_SALES_CONTRACT || pageTypeId == Enum.PageType.INDIRECT_SALES_CONTRACT)) {
    return false;
  }
  return true;
};

export const isInDirectContract = (pageTypeId?: number) => {
  if (pageTypeId && pageTypeId == Enum.PageType.INDIRECT_SALES_CONTRACT) {
    return true;
  }
  return false;
};

export const initApplyPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseCode.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};

  if (params.salesContractId) {
    // 变更申请
    const salesContractResponse = await SalesContract.get(params.salesContractId);
    viewEntity.salesContract = salesContractResponse.data;
    if (!viewEntity.salesContract) {
      result.message = "无效的客户合同";
      return result;
    }
    if (salesContractResponse.data.formSalesContractId) {
      const formSalesContractResponse = await FormSalesContract.get(salesContractResponse.data.formSalesContractId);
      viewEntity.formSalesContract = formSalesContractResponse.data;
    }
    viewEntity.createOrModify = 2;
  } else if (params.formId) {
    // 从草稿打开
    const formSalesContractResponse = await FormSalesContract.get(params.formId);
    viewEntity.formSalesContract = formSalesContractResponse.data;
    if (!viewEntity.formSalesContract) {
      result.message = "无效的草稿单";
      return result;
    }
    viewEntity.formId = viewEntity.formSalesContract.formSalesContractId;
    viewEntity.statusId = Enum.FormStatus.DRAFT;
    viewEntity.createOrModify = viewEntity.formSalesContract.createOrModify;
  } else if (params.relatedFormId) {
    const formSalesContractResponse = await FormSalesContract.get(params.relatedFormId);
    viewEntity.formSalesContract = formSalesContractResponse.data;
    if (params.relatedType == Enum.FormRelationType.COPY) {
      // 复制并编辑
      if (!viewEntity.formSalesContract) {
        result.message = "无效的客户合同申请单";
        return result;
      }
      viewEntity.relatedFormId = undefined;
      viewEntity.relationType = undefined;
      viewEntity.formSalesContract.lastFormSalesContractId = undefined;
      viewEntity.formSalesContract.contractVersion = undefined;
    } else {
      // 退回待修改
      if (!viewEntity.formSalesContract) {
        result.message = "无效的退回待修改单";
        return result;
      }
      viewEntity.relationType = Enum.FormRelationType.REJECTED;
      viewEntity.relatedFormId = viewEntity.formSalesContract.formSalesContractId;
      viewEntity.createOrModify = viewEntity.formSalesContract.createOrModify;
    }
  } else {
    // 直接申请
    viewEntity.bohqId = params.bohqId;
    viewEntity.pageTypeId = params.pageTypeId;
    viewEntity.formTypeId = params.formTypeId;
  }

  if (viewEntity.formSalesContract) {
    viewEntity.bohqId = viewEntity.formSalesContract.bohqId;
    viewEntity.pageTypeId = viewEntity.formSalesContract.pageTypeId;
    viewEntity.formTypeId = viewEntity.formSalesContract.formTypeId;
    viewEntity.custSys = (await CustSys.get(String(viewEntity.formSalesContract.custSysCode))).data;
    viewEntity.soldTo = (await SoldTo.get(String(viewEntity.formSalesContract.soldToCode))).data;
  }

  if (viewEntity.pageTypeId) {
    viewEntity.pageType = (await PageType.get(viewEntity.pageTypeId)).data;
  }

  result.code = ResponseCode.SUCCESS;
  result.data = viewEntity;
  return result;
}


export const initApprovalPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId) {
    const formSalesContractResponse = await FormSalesContract.get(params.formId);
    viewEntity.formSalesContract = formSalesContractResponse.data;
    if (!viewEntity.formSalesContract) {
      result.message = "无效的合同申请单";
      return result;
    }
    viewEntity.createOrModify = formSalesContractResponse.data.createOrModify;

    const formResponse = await FormApi.get(params.formId);
    viewEntity.form = formResponse.data;
    if (viewEntity.form.pageGroupId) {
      const pageGroupResponse = await PageGroup.get(viewEntity.form.pageGroupId);
      viewEntity.pageGroup = pageGroupResponse.data;
    }

    if (viewEntity.formSalesContract.salesContractId) {
      const salesContractResponse = await SalesContract.get(viewEntity.formSalesContract.salesContractId);
      viewEntity.salesContract = salesContractResponse.data;
    }
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
};

export const initDisplyPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.salesContractId) {
    const salesContractResponse = await SalesContract.get(params.salesContractId);
    viewEntity.salesContract = salesContractResponse.data;
    if (!viewEntity.salesContract) {
      result.message = "无效的客户合同";
      return result;
    }
    viewEntity.custSys = (await CustSys.get(String(viewEntity.salesContract.custSysCode))).data;
    viewEntity.soldTo = (await SoldTo.get(String(viewEntity.salesContract.soldToCode))).data;
    if (viewEntity.salesContract.bohqId) {
      viewEntity.bohq = (await BOHQ.get(viewEntity.salesContract.bohqId)).data;
    }
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
};
