import {useEffect, useState} from 'react';
import {DetailGrid, Utility} from '@/components/GridWidget';
import {NsGrid} from '@/components/GridWidget/GridDefProps';
import type {WdiModalAppResult, WdiModalProps} from '@/components/WdiControls';
import {WdiCardTitle} from '@/components/WdiControls';
import {Button, message} from 'antd';
import DataStore from '@/components/GridWidget/DataStore';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {initFieldDisplayFormater} from '@/components/WdiBus/TableColumnRanderFormats';
import {Enum} from '@/enums/system';
import {GridApi} from '@/services/gridapi';
import SalesContractTermsPrevApp from './detail-prev';
import SalesContractTermsEditApp from './detail-edit';
import SalesContractTermsAutoEditApp from './detail-edit-auto';
import SalesContractTermsNonAutoEditApp from "@/pages/contract/SalesContractApply/components/detail-edit-non-auto";
import {isDealerContract, isInDirectContract} from "@/pages/contract/SalesContract/components/data";
import {ResponseStatus} from '@/utils/request';
import {ResponseCode} from "@/utils/response";

export const ModalAppTypeEnums = {
  None: 0,
  Picker: 1,
  // 随量/随额(off invoice)
  InsertAuto: 2,
  EditAuto: 3,
  // 手动申请:
  InsertApply: 4,
  EditApply: 5,
  // 固定类:
  InsertNonAuto: 6,
  EditNonAuto: 7,
  // on invoice
  InsertImport: 8,
  EditImport: 9,
};

const convertToModalType = (processMode: number, isEdit: boolean) => {
  const responseResult = {
    code: ResponseCode.SUCCESS,
    data: ModalAppTypeEnums.None,
    message: ""
  };
  if (isEdit) {
    if (processMode == Enum.ProcessMode.AUTO_ACCRUAL_AND_SETTLE) {
      responseResult.data = ModalAppTypeEnums.EditAuto;
    } else if (processMode == Enum.ProcessMode.NONE_AUTO_ACCRUAL_AND_SETTLE) {
      responseResult.data = ModalAppTypeEnums.EditNonAuto;
    } else if (processMode == Enum.ProcessMode.NON_TPM_IMPORT) {
      responseResult.data = ModalAppTypeEnums.EditImport;
    } else {
      responseResult.code = ResponseCode.UNKNOWN;
      responseResult.message = '无效的处理方式!';
    }
  } else {
    if (processMode == Enum.ProcessMode.AUTO_ACCRUAL_AND_SETTLE) {
      responseResult.data = ModalAppTypeEnums.InsertAuto;
    } else if (processMode == Enum.ProcessMode.NONE_AUTO_ACCRUAL_AND_SETTLE) {
      responseResult.data = ModalAppTypeEnums.InsertNonAuto;
    } else if (processMode == Enum.ProcessMode.NON_TPM_IMPORT) {
      responseResult.data = ModalAppTypeEnums.InsertImport;
    } else {
      responseResult.code = ResponseCode.UNKNOWN;
      responseResult.message = '无效的处理方式!';
    }
  }
  return responseResult;
};

export type DetailForApplyProps = {
  data?: {
    form?: API.Form,
    createOrModify?: number;
  },
  pageTypeId?: number;
  getHeaderInfo?: () => any;
  dataSource?: any[];
  onChange?: (dataSource: any) => void;
};

const SalesContractTermsDetails: React.FC<DetailForApplyProps> = (props) => {

  // Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const {pageTypeId, getHeaderInfo, dataSource, onChange} = props;

  /**合同条款明细 */
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false});

  const onClose = () => {
    setModalProps({visible: false, type: ModalAppTypeEnums.None});
  };

  const onPrevSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const {processMode} = result.data.row;
      const responseModalResult = convertToModalType(processMode, false);
      if (responseModalResult.code !== ResponseCode.SUCCESS) {
        message.error(responseModalResult.message);
        return;
      }
      setModalProps({
        visible: true,
        title: '新建明细合同条款',
        type: responseModalResult.data,
        id: 0, //新增传0
        data: result.data,
      });
    }
  };

  const onEditSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const row = result?.data;
      if (
        modalProps.type === ModalAppTypeEnums.EditAuto ||
        modalProps.type === ModalAppTypeEnums.EditNonAuto ||
        modalProps.type === ModalAppTypeEnums.EditImport
      ) {
        const record = modalProps.data.row;
        const newItem = {...row};
        newItem.uid = record.uid;
        const newDataSouce = DataStore.edit(dataSource, newItem);
        onChange?.(newDataSouce);
      } else if (
        modalProps.type === ModalAppTypeEnums.InsertAuto ||
        modalProps.type === ModalAppTypeEnums.InsertNonAuto ||
        modalProps.type === ModalAppTypeEnums.InsertImport
      ) {
        const newItem = {...row};
        onChange?.(DataStore.add(dataSource, newItem));
      }
    }
    setModalProps({visible: false, type: ModalAppTypeEnums.None});
  };

  const onPreModal = () => {
    const headerInfo = getHeaderInfo?.();
    if (!headerInfo || !headerInfo.revenueForecast) {
      message.error('请先填写预估销售额');
      return;
    }
    if (!isDealerContract(pageTypeId) && (!headerInfo || !headerInfo.custSysCode)) {
      message.error('请先选择客户集团');
      return;
    }
    if (!headerInfo || !headerInfo.soldToCode) {
      message.error('请先选择售达方');
      return;
    }

    setModalProps({
      visible: true,
      type: ModalAppTypeEnums.Picker,
      title: '新建明细合同条款',
      id: null,
      data: {
        row: null,
        rowIndex: -1,
        rows: dataSource,
        pageTypeId: pageTypeId,
        soldToCode: headerInfo.soldToCode,
        custSysCode: headerInfo.custSysCode,
        revenueForecast: headerInfo.revenueForecast
      },
    });
  };

  useEffect(() => {
    const formDefID = isInDirectContract(pageTypeId) ? 'FormSalesContractTerms2' : 'FormSalesContractTerms';
    GridApi.searchConfig({id: formDefID, allowModifyColumns: false}).then(response => {
      const settings: any = Object.assign({}, response.data, {
        rowKey: 'uid',
        actionsAlign: NsGrid.ActionsAlign.Left,
        showExpandedRow: true,
        filterMenuActions: (actions: any, record: any) => {
          const newActions: any[] = [];
          if (actions) {
            actions.forEach((item: any) => {
              if (item.name == 'SystemDelete') {
                if (!record.salesContractTermsId) {
                  newActions.push(item);
                }
              } else {
                newActions.push(item);
              }
            });
          }
          return newActions;
        }
      });
      Utility.setActionCallback(
        settings.formSchema.actions,
        'Update',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          const pageType = context.params?.pageTypeId;
          const headerInfo = context.params?.getHeaderInfo?.();
          if (!headerInfo || !headerInfo.revenueForecast) {
            message.error('请先填写预估销售额');
            return;
          }
          if (!isDealerContract(pageType) && (!headerInfo || !headerInfo.custSysCode)) {
            message.error('请先选择客户集团');
            return;
          }
          if (!headerInfo || !headerInfo.soldToCode) {
            message.error('请先选择售达方');
            return;
          }
          const {processMode} = record;
          const responseModalResult = convertToModalType(processMode, true);
          if (responseModalResult.code !== ResponseCode.SUCCESS) {
            message.error(responseModalResult.message);
            return;
          }
          setModalProps({
            visible: true,
            title: '编辑明细合同条款',
            type: responseModalResult.data,
            data: {
              row: record,
              rows: context.dataSource,
              rowIndex: DataStore.getRowIndex(context.dataSource, record.uid),
              pageTypeId: pageType,
              soldToCode: headerInfo.soldToCode,
              custSysCode: headerInfo.custSysCode,
              revenueForecast: headerInfo.revenueForecast
            }
          });
        },
      );

      Utility.setActionCallback(
        settings.formSchema.actions,
        'SystemDelete',
        async (grid: any, action: any, record: any) => {
          if (confirm("确定要删除该行吗？")) {
            onChange?.(DataStore.del(grid.getContext().dataSource, record.uid));
            message.success("删除成功");
          }
        },
      );

      settings.formSchema.allFields.rowNumber.getDisplayFormater = (_t: any, record: any, index: any) => {
        return `${record.rowNumber || index + 1}`;
      };
      initFieldDisplayFormater(settings.formSchema.allFields);
      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.processModeName.width = 150;
      settings.formSchema.allFields.revenueTypeName.width = 110;
      settings.formSchema.allFields.productNamesRebate.width = 250;
      settings.formSchema.allFields.settlementFrequencyName.width = 80;
      settings.formSchema.allFields.termsAmount.width = 100;
      settings.formSchema.allFields.rateOriginal.width = 100;
      settings.formSchema.allFields.productNamesReach.width = 100;
      settings.formSchema.allFields.remark.width = 100;
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  let maxAllowedFields = ['productNamesRebate', 'productNamesReach', 'rateOriginal', 'quantityForecast', 'revenueForecast', 'termsAmount', 'remark'];
  if (isInDirectContract(pageTypeId)) {
    maxAllowedFields = ['productNamesRebate', 'productNamesReach', 'rateOriginal', 'quantityForecast', 'revenueForecast', 'termsAmount', 'rateDealer', 'termsAmountDealer', 'remark'];
  }
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return maxAllowedFields;
    },
  };

  return (
    <WdiPanel
      title={<WdiCardTitle iconType={Enum.IconType.CONTRACT_TERM} title="合同条款"/>}
      extra={
        <Button
          type="primary"
          onClick={() => {
            onPreModal()
          }}
        >
          新建
        </Button>
      }
    >
      {isLoaded && modalProps.type === ModalAppTypeEnums.Picker && (
        <SalesContractTermsPrevApp
          {...modalProps}
          onClose={onClose}
          onSave={onPrevSave}
        />
      )}
      {isLoaded &&
        (modalProps.type === ModalAppTypeEnums.InsertAuto ||
          modalProps.type === ModalAppTypeEnums.EditAuto) && (
          <SalesContractTermsAutoEditApp
            {...modalProps}
            onClose={onClose}
            onSave={onEditSave}
          />
        )}
      {isLoaded &&
        (modalProps.type === ModalAppTypeEnums.InsertNonAuto ||
          modalProps.type === ModalAppTypeEnums.EditNonAuto) && (
          <SalesContractTermsNonAutoEditApp
            {...modalProps}
            onClose={onClose}
            onSave={onEditSave}
          />
        )}
      {isLoaded &&
        (modalProps.type === ModalAppTypeEnums.InsertImport ||
          modalProps.type === ModalAppTypeEnums.EditImport) && (
          <SalesContractTermsEditApp
            {...modalProps}
            onClose={onClose}
            onSave={onEditSave}
          />
        )}
      {isLoaded && (
        <DetailGrid
          {...pageGridSettings}
          params={{pageTypeId, getHeaderInfo}}
          autoColumn={['rowNumber']}
        />
      )}
    </WdiPanel>
  );
};
export default SalesContractTermsDetails;

