import {WdiModalAppResult, WdiModalProps} from "@/components/WdiControls";
import {DownOutlined} from "@ant-design/icons";
import {Dropdown, Menu, message, Space} from "antd";
import {useEffect, useState} from "react";
import PageControl, {ModuleType, PageMode} from '@/components/WdiBus/FormPage';
import {Position} from "@/services/basic/position";
import {Enum} from "@/enums/system";
import {ResponseStatus} from "@/utils/request";
import {SalesContract} from "@/services/contract/salescontract";
import IsAutoAppendEdit from "@/pages/contract/SalesContract/components/IsAutoAppendEdit";

type MoreWrapperProps = {
  salesContractId?: number;
  formSalesContractId: number;
  contractStatusId?: number;
  onClose?: (result?: WdiModalAppResult) => void;
  callback?: (result?: WdiModalAppResult) => void;
};
const UpdateApplyButton: React.FC<MoreWrapperProps> = (props: MoreWrapperProps) => {
  const {salesContractId} = props;

  const handleClick = (e: any) => {
    if (salesContractId) {
      PageControl.apply({
        mode: PageMode.Apply,
        moduleType: ModuleType.Contract,
        data: {salesContractId: salesContractId}
      });
    }
  }
  return (
    <>
      <div onClick={handleClick}>
        合同变更
      </div>
    </>
  )
}
const DeactiveButton: React.FC<MoreWrapperProps> = (props: MoreWrapperProps) => {
  const {onClose, salesContractId} = props;
  const handleClick = (e: any) => {
    if (window.confirm('合同无效后不会再生成此合同的返利，且无效后不可撤销，确认吗？')) {
      SalesContract.deactive(salesContractId || 0).then(response => {
        if (response.code == ResponseStatus.SUCCESS) {
          message.success("操作成功！");
          PageControl.show({
            mode: PageMode.Approval,
            moduleType: ModuleType.Contract,
            data: {salesContractId: salesContractId}
          });
          if (onClose) {
            onClose();
          } else {
            window.location.reload();
          }
        } else {
          message.error(response.message);
        }
      });
    }
  }
  return (
    <div onClick={handleClick}>
      合同无效
    </div>
  )
}
export const ModifyIsAutoAppendButton: React.FC<MoreWrapperProps> = (props: MoreWrapperProps) => {
  const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({visible: false});
  const {onClose, salesContractId} = props;
  const onModalClose = (result?: WdiModalAppResult) => {
    setWdiModalProps({visible: false});
  };
  const onSave = () => {
    setWdiModalProps({visible: false});
    PageControl.show({
      mode: PageMode.Approval,
      moduleType: ModuleType.Contract,
      data: {salesContractId: salesContractId}
    });
    if (onClose) {
      onClose();
    } else {
      window.location.reload();
    }
  }
  const handleClick = (e: any) => {
    setWdiModalProps({visible: true, title: '修改是否自动费用追溯', id: salesContractId});
  };

  return (
    <>
      {wdiModalProps.visible &&
        <IsAutoAppendEdit {...wdiModalProps} onClose={onModalClose} onSave={onSave}/>
      }
      <div onClick={handleClick}>
        修改是否自动追溯
      </div>
    </>
  )
}
export const SalesContractMoreWapper: React.FC<MoreWrapperProps> = (props: MoreWrapperProps) => {
  const [menuItems, setMenuItems] = useState<any[]>([]);

  useEffect(() => {
    const newMenuItems: any[] = [];
    Promise.all([
      Position.access({
        useCaseId: Enum.UseCase.SALES_CONTRACT,
        operateTypeIds: [Enum.OperateType.APPLY]
      }),
      Position.access({
        useCaseId: Enum.UseCase.SALES_CONTRACT,
        operateTypeIds: [Enum.OperateType.SCRAP]
      })
    ]).then((dataArray: any[]) => {
      const allowApplyRes = dataArray[0];
      let allowApply = false;
      if (allowApplyRes.code == ResponseStatus.SUCCESS && allowApplyRes.data == true) {
        allowApply = true;
      }
      const allowScrapRes = dataArray[1];
      let allowScrap = false;
      if (allowScrapRes.code == ResponseStatus.SUCCESS && allowScrapRes.data == true) {
        allowScrap = true;
      }
      if (props.contractStatusId == Enum.SalesContractStatus.VALID) {
        if (allowApply && props.formSalesContractId) {
          newMenuItems.push({
            label: (
              <UpdateApplyButton {...props} />
            ),
            key: "UpdateApply"
          })
        }
        if (allowScrap) {
          newMenuItems.push({
            label: (
              <DeactiveButton {...props} />
            ),
            key: "Deactive"
          });
          newMenuItems.push({
            label: (
              <ModifyIsAutoAppendButton {...props} />
            ),
            key: "ModifyIsAutoAppend"
          });
        }
        setMenuItems(newMenuItems);
      }
    })
  }, [props.formSalesContractId]);

  return (
    <>
      {menuItems && menuItems.length > 0 &&
        <Dropdown overlay={
          <Menu
            items={menuItems}
          />
        }>
          <a onClick={e => e.preventDefault()}>
            <Space>
              More <DownOutlined/>
            </Space>
          </a>
        </Dropdown>
      }
    </>
  )
}
