import React, {useEffect, useRef, useState} from 'react';
import {Checkbox, Col, Form, message, Row} from 'antd';
import ProForm, {ProFormInstance} from '@ant-design/pro-form';
import {FileUploadUtility, WdiFileList} from '@/components/WdiControls/WdiFileUpload';
import {FormPageProps} from '@/components/WdiBus/FormPage';
import {
  initDisplyPageContext,
  isDealerContract,
  isInDirectContract,
  ViewEntityProps
} from '../../SalesContract/components/data';
import {ResponseStatus} from '@/utils/request';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {WdiCardTitle, wdiCore, WdiInput, WdiTextArea} from '@/components/WdiControls';
import SalesContractTermsForApproval from './details';
import {SalesContractTerms} from '@/services/contract/salescontractterms';
import DataStore from '@/components/GridWidget/DataStore';
import {WdiButtonContainer} from '@/components/WdiBus/Buttons';
import {SalesContractMoreWapper} from '../../SalesContract/components/Button';
import SalesContractHistoryForPanel from './history';
import {Enum} from '@/enums/system';
import {FormSalesContract} from "@/services/contract/formsalescontract";

const SalesContractApproval: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>();
  const [salesContractHistorys, setSalesContractHistorys] = useState<any[]>();
  const [entity, setEntity] = useState<ViewEntityProps>();
  const [pageTypeId, setPageTypeId] = useState<number>();

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initDisplyPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity = result.data;
        setPageTypeId(viewEntity.salesContract.pageTypeId);
        const {salesContract, custSys, soldTo, bohq} = viewEntity;
        if (salesContract) {
          formRef.current?.setFieldsValue({
            contractDate: wdiCore.Date.ToString(salesContract.beginDate, "yyyyMM") + ' ~ ' + wdiCore.Date.ToString(salesContract.endDate, "yyyyMM"),
            tpmContractCode: salesContract.tpmContractCode,
            contractCode: salesContract.contractCode,
            contractName: salesContract.contractName,
            owner: salesContract.owner,
            customerSignatory: salesContract.customerSignatory,
            signDate: wdiCore.Date.ToString(salesContract.signDate, "yyyy-MM-dd"),
            isAutoAppend: salesContract.isAutoAppend,
            isOfficial: salesContract.isOfficial ? '正式' : '计划',
            remark: salesContract.remark,
            ttForTs: salesContract.ttForTs,
            fileInfo: FileUploadUtility.parse(salesContract.fileName, salesContract.filePath),
            revenueForecast: wdiCore.Number.AmountToString(salesContract.revenueForecast),
            elnRateMax: wdiCore.Number.RateToString(salesContract.elnRateMax, 4),
            expenseForecast: wdiCore.Number.AmountToString(salesContract.expenseForecast),
            rateDealerMax: wdiCore.Number.RateToString(salesContract.rateDealerMax, 4)
          });
          SalesContractTerms.getSalesContractTerms(salesContract.salesContractId).then((response) => {
            setDataSource(DataStore.init(response.data));
          });
          FormSalesContract.getHistory(salesContract.salesContractId).then((response) => {
            setSalesContractHistorys(DataStore.init(response.data));
          });
        }
        if (soldTo) {
          formRef.current?.setFieldsValue({
            soldToCode: wdiCore.String.CodeOrName(soldTo.soldToCode, soldTo.soldToName),
          });
        }
        if (custSys) {
          formRef.current?.setFieldsValue({
            custSysCode: wdiCore.String.CodeOrName(custSys.custSysCode, custSys.custSysName),
          });
        }
        if (bohq) {
          formRef.current?.setFieldsValue({
            bohqId: bohq.bohqName
          });
        }
        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      var title = "";
      if (pageTypeId != null && pageTypeId == Enum.PageType.DIRECT_SALES_CONTRACT) {
        title = "直供客户合同";
      } else if (pageTypeId != null && pageTypeId == Enum.PageType.DEALER_SALES_CONTRACT) {
        title = "经销商合同";
      } else {
        title = "间供客户合同";
      }
      if (entity?.salesContract?.tpmContractCode) {
        title += `【${entity.salesContract.tpmContractCode}】`;
      }
      if (entity?.salesContract?.contractStatusId == Enum.SalesContractStatus.EDITING) {
        title += "，修改中";
      } else if (entity?.salesContract?.contractStatusId == Enum.SalesContractStatus.VALID) {
        title += "，有效";
      } else {
        title += "，无效";
      }

      if (props.setPageTitle) props.setPageTitle(<div>
        <span>{title}</span>
        <WdiButtonContainer>
          <SalesContractMoreWapper
            onClose={props.onClose}
            callback={props.callback}
            salesContractId={Number(entity?.salesContract?.salesContractId)}
            formSalesContractId={Number(entity?.salesContract?.formSalesContractId)}
            contractStatusId={entity?.salesContract?.contractStatusId}
          />
        </WdiButtonContainer>
      </div>);

    }
  }, [props.setPageTitle, entity]);

  return (
    <>
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (<></>)
          },
        }}
      >
        <WdiPanel collapsed={true} title={<WdiCardTitle iconType={Enum.IconType.BASE_INFO} title='基本信息'/>}>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={4}
                 hidden={isDealerContract(pageTypeId)}>
              <Form.Item name="custSysCode" label="客户集团">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="soldToCode" label="售达方">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="contractDate" label="合同期间">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="tpmContractCode" label="TPM合同编号">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="contractCode" label="合同编号">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="contractName" label="合同名称">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="owner" label="合同负责人">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="customerSignatory" label="客户方签约人">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="signDate" label="签约日期">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="isAutoAppend" label="是否自动费用追溯" valuePropName="checked">
                <Checkbox disabled></Checkbox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="isOfficial" label="计划/正式合同">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="bohqId" label="费用归属">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="remark" label="合同备注">
                <WdiTextArea rows={4} readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="ttForTs" label="需通过TP预提支付的合同费用">
                <WdiTextArea rows={4} readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo" label="附件"
                valuePropName='fileList'
                getValueFromEvent={(e: any) => {
                  return e.fileList || [];
                }}>
                <WdiFileList/>
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.LIMITED_INFO} title='合同费用预测'/>} collapsed={true}>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={4}>
              <Form.Item name="revenueForecast" label="预估销售额(不含税)*">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="elnRateMax" label="ELN预估承担点数">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="expenseForecast" label="ELN预估费用">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="rateDealerMax" label="经销商承担点数"
                         hidden={!isInDirectContract(pageTypeId)}>
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        {dataSource && <SalesContractTermsForApproval dataSource={dataSource} pageTypeId={pageTypeId}/>}
        {salesContractHistorys && <SalesContractHistoryForPanel dataSource={salesContractHistorys}/>}
      </ProForm>
    </>
  );
};
export default SalesContractApproval;
