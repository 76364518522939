import {wdiCore, WdiModalAppProps} from "@/components/WdiControls";
import React, {Key, useEffect, useRef, useState} from "react";
import {WdiModal} from "@/components/WdiControls/WdiModalForm";
import {Button, message} from "antd";
import {GridApi} from "@/services/gridapi";
import {NsGrid} from "@/components/GridWidget/GridDefProps";
import {FilterUtility, PageGrid, Utility} from "@/components/GridWidget";
import {SoldTo} from "@/services/mdm/soldto";
import {SA} from "@/services/report/sa";
import {CustSys} from "@/services/mdm/custsys";
import download from "@/utils/download";
import {formatter, ResponseStatus} from "@/utils/request";
import {DataOptions} from "@/services/basic/metadata";
import {VatType} from "@/services/mdm/vattype";
import { Staff } from '@/services/basic/staff';


const FormSaDetailSelect: React.FC<WdiModalAppProps> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  //获取GridWdiget实例
  const gridRef: any = useRef();
  const getGridRef = (el: any) => {
    gridRef.current = el;
  };


  const onSelectChange = (newSelectedRowKeys: Key[], newSelectedRows: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };


  useEffect(() => {
    Promise.all([GridApi.searchConfig({
      id: 'FormSaDetailForSpSelect',
      allowModifyColumns: true
    })]).then((dataArray: any[]) => {
      const responseResult = dataArray[0];
      const bohqId = props.data.bohqId;
      const data = responseResult.data;
      const settings: any = Object.assign({}, data, {
        dataLoading: async (params: any) => {
          return await SA.queryForSpPicker(Object.assign({}, params));
        },
        rowKey: 'formSaDetailId',
        actionsAlign: NsGrid.ActionsAlign.None,
        filterFormat: NsGrid.FilterFormat.Default,
        viewMode: NsGrid.ViewMode.Advance,
        fixedQueryValues: [
          {
            title: "bohqId",
            field: "bohqId",
            type: "Number",
            opt: NsGrid.FilterOptType.EQ,
            value: bohqId
          },
        ]
      });
      settings.formSchema.allFields.activityStartDate.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Date.Join(record.activityStartDate, record.activityEndDate);
      }
      settings.formSchema.allFields.soldTos.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.String.CodeOrName(record.soldToCode, record.soldToName);
      }
      settings.formSchema.allFields.soldToMasterCode.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.String.CodeOrName(record.soldToMasterCode, record.soldToMasterName);
      }
      settings.formSchema.allFields.custSyss.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.String.CodeOrName(record.custSysCode, record.custSysName);
      }
      settings.formSchema.allFields.soldTo.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: async (params: any) => {
          return await SoldTo.ajaxSearch(Object.assign({}, params)).then(res => {
            return res.data?.map(item => {
              return {
                value: item.soldToCode,
                label: item.codeOrName,
              }
            })
          });
        },
        defaultParamKey: 'soldToCodes',
        remote: true
      });
      settings.formSchema.allFields.custSys.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: async (params: any) => {
          return await CustSys.ajaxSearch(Object.assign({}, params)).then(res => {
            return res.data?.map(item => {
              return {
                value: item.custSysCode,
                label: item.codeOrName,
              }
            })
          });
        },
        defaultParamKey: 'custSysCodes',
        remote: true
      });

      //已审批人
      settings.formSchema.allFields.approverUserIds.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return Staff.search({}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.staffName,
                value: item.staffId
              }
            })
          })
        },
        isMulti: true
      });

      //支付方式、税率类型、发票类型
      settings.formSchema.allFields.paymentMethodId.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return DataOptions.search({typeGroup: 5}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.name,
                value: item.value
              }
            })
          })
        },
      });

      settings.formSchema.allFields.vatTypeId.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return VatType.search({}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.vatTypeName,
                value: item.vatTypeId
              }
            })
          })
        },
      });

      settings.formSchema.allFields.invoiceType.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return DataOptions.search({typeGroup: 106}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.name,
                value: item.value
              }
            })
          })
        },
      });

      Utility.setActionCallback(settings.formSchema.toolbarActions, 'Export',
        async (grid: any, action: any) => {
          if (grid && grid.getGridArgs) {
            const jqxGridArgs = grid.getGridArgs();

            await SA.exportFormSaDetailsSpForPicker(Object.assign({}, jqxGridArgs)).then(res => {
              download(SA.Path.DownLoadFormSaDetailPicker, formatter({
                method: 'POST',
                body: {
                  formSaDetailQueryPos: res.data,
                },
              }))
            })

            // if (rowSelection.selectedRowKeys && rowSelection.selectedRowKeys.length > 0) {
            //   let filterOptions = JSON.parse(jqxGridArgs.filterOptions);
            //   if (!filterOptions) {
            //     filterOptions = [];
            //   }
            //   filterOptions.push({
            //     title: "formSaDetailIds",
            //     field: "formSaDetailIds",
            //     type: "",
            //     opt: 8,
            //     value: rowSelection.selectedRowKeys
            //   })

            //   jqxGridArgs.filterOptions = JSON.stringify(filterOptions);

            //   await SA.exportFormSaDetailsSpForPicker(Object.assign({}, jqxGridArgs)).then(res => {
            //     download(SA.Path.DownLoadFormSaDetailPicker, formatter({
            //       method: 'POST',
            //       body: {
            //         formSaDetailQueryPos: res.data,
            //       },
            //     }))
            //   })
            // } else {
            //   message.error('请选择要导出的活动明细');
            // }


          }
        },
      );

      settings.formSchema.allFields.formNo.width = 150;
      settings.formSchema.allFields.activityName.width = 200;
      settings.formSchema.allFields.soldTos.width = 250;
      settings.formSchema.allFields.soldToMasterCode.width = 250;
      settings.formSchema.allFields.custSyss.width = 250;
      settings.formSchema.allFields.amountSs.width = 100;

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);
  return (
    <>
      <WdiModal
        title={props.title}
        visible={props.visible}
        width={1400}
        footer={
          <>
            <Button type='primary' onClick={(grid: any) => {
              const jqxGridArgs = gridRef.current.getGridArgs();

              SA.exportFormSaDetailsSpForPicker(Object.assign({}, jqxGridArgs)).then(res => {
                props.onSave?.({
                  code: ResponseStatus.SUCCESS,
                  data: res.data
                })
              })
            }}>
              全部添加
            </Button>
            <Button type='primary' onClick={() => {
              if (selectedRowKeys.length == 0) {
                message.error('请至少选择一条数据');
              } else {
                props.onSave?.({
                  code: ResponseStatus.SUCCESS,
                  data: selectedRows
                })
              }
            }}>
              添加
            </Button>
            <Button onClick={() => {
              props.onClose ? props.onClose() : null
            }}>
              关闭
            </Button>
          </>
        }
        onCancel={() => {
          props.onClose ? props.onClose() : null;
        }}
        maskClosable={false}
        destroyOnClose
      >
        {isLoaded && <PageGrid
          antdProps={{
            rowSelection: {selectedRowKeys, selectedRows, onChange: onSelectChange},
            pagination: {
              pageSize: 10,
              current: 1,
              showSizeChanger: true
            }
          }
          }
          {...gridSettings} getInstance={getGridRef}/>}
      </WdiModal>
    </>
  )
}
export default FormSaDetailSelect;
