import React, {useEffect, useRef, useState} from 'react';
import {Checkbox, Col, DatePicker, Form, Input, message, Row} from 'antd';
import type {ProFormInstance} from '@ant-design/pro-form';
import {ProForm} from '@ant-design/pro-form';
import {WdiCardTitle, wdiCore, WdiInput, WdiModalAppResult} from '@/components/WdiControls';
import type {FormPageProps} from '@/components/WdiBus/FormPage';
import {ApplyFooter, ApplyFooterType} from '@/components/WdiBus/ApplyFooter';
import {FileUploadUtility, WdiFileUpload} from '@/components/WdiControls/WdiFileUpload';
import {ResponseStatus} from '@/utils/request';
import type {ViewEntityProps} from '../../SalesContract/components/data';
import {initApplyPageContext} from '../../SalesContract/components/data';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import SalesContractTermsDetails from './details';
import {WdiSelect} from '@/components/WdiControls/WdiSelect';
import {CustSys} from '@/services/mdm/custsys';
import moment from 'moment';
import DataStore from '@/components/GridWidget/DataStore';
import {Enum} from '@/enums/system';
import {SoldTo} from "@/services/mdm/soldto";
import {FormSalesContract} from "@/services/contract/formsalescontract";
import {WdiInputNumber} from "@/components/WdiControls/WdiInputNumber";
import {FormSalesContractTerms} from "@/services/contract/formsalescontractterms";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

const getPageResult = (values: any, viewEntity: ViewEntityProps, pageType: API.PageType, statusId: number, dataSource: any[]) => {
  const result: WdiModalAppResult = {code: ResponseStatus.UNKNOWN};

  const {contractDate, fileInfo, signDate, isAutoAppend, isOfficial, custSysCode, soldToCode, ...entity} = values;

  if (contractDate && contractDate.length == 2) {
    entity.beginDate = moment(contractDate[0]).format('YYYY-MM-DD');
    entity.endDate = moment(contractDate[1]).format('YYYY-MM-DD');
  } else {
    result.message = '无效的合同期间';
    return result;
  }
  if (signDate) {
    entity.signDate = moment(signDate).format('YYYY-MM-DD');
  }
  if (FileUploadUtility.isUploading(fileInfo)) {
    result.message = '附件正在上传中!';
    return result;
  }
  if (FileUploadUtility.isError(fileInfo)) {
    result.message = '附件存在错误文件!';
    return result;
  }
  const info = FileUploadUtility.serialize(fileInfo);
  entity.fileName = info?.names;
  entity.filePath = info?.urls;
  entity.isAutoAppend = Boolean(isAutoAppend);
  entity.isOfficial = Boolean(isOfficial == '0' ? false : true);
  if (custSysCode?.value !== undefined) {
    entity.custSysCode = custSysCode.value;
  } else {
    entity.custSysCode = custSysCode;
  }

  if (soldToCode?.value !== undefined) {
    entity.soldToCode = soldToCode.value;
  } else {
    entity.soldToCode = soldToCode;
  }

  const data: { form: any, formSalesContract: any, formSalesContractTermsList: any[] } = {
    form: {
      statusId: statusId,
      formTypeId: viewEntity.formTypeId,
      pageGroupId: pageType.pageGroupId,
      pageTypeId: pageType.pageTypeId
    },
    formSalesContract: {},
    formSalesContractTermsList: []
  };

  if (viewEntity.formSalesContract) {
    // // 从草稿打开和退回待修改的单据都需要记录合同版本号，提交时需要核对
    // data.formSalesContract.contractVersion = viewEntity.formSalesContract.contractVersion;
    // 退回待修改，需要记录相关单据信息
    if (viewEntity.relationType === Enum.FormRelationType.REJECTED) {
      data.form.relatedFormId = viewEntity.relatedFormId;
      data.form.relationType = viewEntity.relationType;
    } else {
      // 从草稿打开
      data.form.formId = viewEntity.formId;
    }
  }

  data.formSalesContract = entity;
  if (viewEntity.createOrModify == 2) {
    data.formSalesContract.createOrModify = viewEntity.createOrModify;
    if (viewEntity.salesContract) {
      data.formSalesContract.salesContractId = viewEntity.salesContract.salesContractId;
      data.formSalesContract.contractVersion = viewEntity.salesContract.contractVersion;
    } else if (viewEntity.formSalesContract) {
      data.formSalesContract.salesContractId = viewEntity.formSalesContract.salesContractId;
      data.formSalesContract.contractVersion = viewEntity.formSalesContract.contractVersion;
    }
  }
  if (dataSource && dataSource.length) {
    dataSource.forEach((item, itemIndex) => {
      item.rowNumber = itemIndex + 1;
    });
  } else {
    result.message = "合同明细至少填写一条!";
    return result;
  }
  data.formSalesContractTermsList = dataSource;
  result.data = data;
  result.code = ResponseStatus.SUCCESS;
  return result;
};

const convertFormSalesContractToPageProps: any = (formSalesContract: API.FormSalesContract): any => {
  return {
    contractDate: [moment(formSalesContract.beginDate), moment(formSalesContract.endDate)],
    contractCode: formSalesContract.contractCode,
    contractName: formSalesContract.contractName,
    owner: formSalesContract.owner,
    customerSignatory: formSalesContract.customerSignatory,
    signDate: formSalesContract.signDate ? moment(formSalesContract.signDate) : null,
    isAutoAppend: formSalesContract.isAutoAppend,
    isOfficial: formSalesContract.isOfficial ? '1' : '0',
    tpmContractCode: formSalesContract.tpmContractCode,
    remark: formSalesContract.remark,
    ttForTs: formSalesContract.ttForTs,
    fileInfo: FileUploadUtility.parse(formSalesContract.fileName, formSalesContract.filePath),
    revenueForecast: formSalesContract.revenueForecast,
    elnRateMax: formSalesContract.elnRateMax,
    expenseForecast: formSalesContract.expenseForecast,
    rateDealerMax: formSalesContract.rateDealerMax,
    modifiedReason: formSalesContract.modifiedReason
  };
};

const getTermsStandardValue: any = (termsContent: string, quantityForecast: number): any => {
  const termsRateRows: any [] = JSON.parse(termsContent);
  let standardValue = 0;
  if (termsRateRows && termsRateRows.length && termsRateRows[0].standardValue) {
    standardValue = termsRateRows[0].standardValue;
  }
  termsRateRows?.forEach((item) => {
    if (item.value <= quantityForecast) {
      standardValue = item.standardValue;
    }
  })
  return standardValue;
}

const isAllProduct = (name: string) => {
  if (name === undefined || name === null || name === "") {
    return true;
  }
  if ("全部" == name || name.endsWith("- 全部")) {
    return true;
  }
  return false;
}

const SalesContractApply: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [entity, setEntity] = useState<ViewEntityProps>();
  // 合同类型
  const [pageType, setPageType] = useState<API.PageType>();
  // 当前合同是否为编辑模式
  const [isEdit, setIsEdit] = useState<boolean | undefined>();
  const [selectCustSysCode, setSelectCustSysCode] = useState<string>();
  const [salesContractId, setSalesContractId] = useState<number>();

  const [dataSource, setDataSource] = useState<any[]>();

  const calcForecastProps = (details: any[], revenueForecast?: number) => {
    const calcResult = {elnRateMax: 0, expenseForecast: 0, rateDealerMax: 0};
    if (details && details.length) {
      details.forEach((item: any) => {
        if (item.rateForecast) {
          calcResult.elnRateMax = calcResult.elnRateMax + item.rateForecast;
        }
        if (item.rateDealer) {
          calcResult.rateDealerMax = calcResult.rateDealerMax + item.rateDealer;
        }
      });
      if (revenueForecast) {
        calcResult.expenseForecast = wdiCore.Number.ToNumber(revenueForecast * calcResult.elnRateMax, 2);
      }
    }
    formRef.current?.setFieldsValue(calcResult);
  };

  const onDetailsChange = (data: any) => {
    setDataSource(data);
    const revenueForecat = formRef.current?.getFieldValue("revenueForecast");
    calcForecastProps(data, revenueForecat);
  };

  const onRevenueForecastBlur = () => {
    const revenueForecast = formRef.current?.getFieldValue("revenueForecast");
    const newDataSource: any[] = [];
    dataSource?.forEach((item) => {
      if (Enum.ProcessMode.AUTO_ACCRUAL_AND_SETTLE == item.processMode) {
        if (item.revenueType == Enum.RevenueType.SELLIN || item.revenueType == Enum.RevenueType.SELLOUT) {
          let qtyRateForecast = 0;
          if (revenueForecast * item.quantityForecast) {
            const qtyStandardValue = getTermsStandardValue(item.termsContent, item.quantityForecast);
            qtyRateForecast = wdiCore.Number.ToNumber(item.quantityForecast * qtyStandardValue / revenueForecast, 6);
          }
          newDataSource.push(Object.assign({}, item, {rateForecast: qtyRateForecast}));
        } else if (item.revenueType == Enum.RevenueType.SELLIN || item.revenueType == Enum.RevenueType.SELLOUT) {
          if (isAllProduct(item.productNamesRebate)) {
            let maxStandardValue = getTermsStandardValue(item.termsContent, 99999999);
            newDataSource.push(Object.assign({}, item, {rateForecast: maxStandardValue}));
          } else {
            let qtyRateForecast = 0;
            if (revenueForecast * item.revenueForecast) {
              const qtyStandardValue = getTermsStandardValue(item.termsContent, item.revenueForecast);
              qtyRateForecast = wdiCore.Number.ToNumber(item.revenueForecast * qtyStandardValue / revenueForecast, 6);
            }
            newDataSource.push(Object.assign({}, item, {rateForecast: qtyRateForecast}));
          }
        } else {
          newDataSource.push(item);
        }
      } else if (Enum.ProcessMode.NONE_AUTO_ACCRUAL_AND_SETTLE == item.processMode) {
        // 固定类重新计算明细行的ELN预估承担点数
        let fixRateForecast = 0;
        if (revenueForecast) {
          fixRateForecast = wdiCore.Number.ToNumber(item.termsAmount / revenueForecast, 6);
        }
        newDataSource.push(Object.assign({}, item, {rateForecast: fixRateForecast}));
      } else {
        newDataSource.push(item);
      }
    });
    setDataSource(newDataSource);
    calcForecastProps(newDataSource, revenueForecast);
  }

  const onCustSysSelectChange = (value: string) => {
    setSelectCustSysCode(value);
  };

  const soldToDataLoading = (params: any) => {
    return SoldTo.ajaxSearch(Object.assign({
      isActive: true,
      withBoHqPermissions: true,
      custSysCode: selectCustSysCode
    }, params)).then((response) => {
        return response.data.map((item) => ({
          value: item.soldToCode,
          label: item.codeOrName,
        }));
      },
    );
  };

  const custSysDataLoading = (params: any) => {
    return CustSys.ajaxSearch(Object.assign({
      isActive: true,
      withBoHqPermissions: true
    }, params)).then((response) => {
      return response.data.map((item) => ({value: item.custSysCode, label: item.codeOrName}));
    });
  };

  const getHeaderInfo = () => {
    const data: any = {};
    if (formRef && formRef.current) {
      const inputSoldToCode = formRef.current?.getFieldValue("soldToCode");
      const inputCustSysCode = formRef.current?.getFieldValue("custSysCode");
      data.revenueForecast = formRef.current?.getFieldValue("revenueForecast");
      data.soldToCode = inputSoldToCode?.value ? inputSoldToCode?.value : inputSoldToCode;
      data.custSysCode = inputCustSysCode?.value ? inputCustSysCode?.value : inputCustSysCode;
    }
    return data;
  };

  const isOfficialOpts = [
    {label: '计划', value: '0'}
    , {label: '正式', value: '1'}
  ];

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApplyPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity = result.data;
        if (viewEntity.formSalesContract) {
          if (viewEntity.createOrModify == 2) {
            setIsEdit(true);
            setSalesContractId(viewEntity.formSalesContract.salesContractId);
          }
          formRef.current?.setFieldsValue(convertFormSalesContractToPageProps(viewEntity.formSalesContract));
        }
        if (viewEntity.soldTo) {
          formRef.current?.setFieldsValue({
            soldToCode: {value: viewEntity.soldTo.soldToCode, label: viewEntity.soldTo.codeOrName}
          });
        }
        if (viewEntity.custSys) {
          formRef.current?.setFieldsValue({
            custSysCode: {value: viewEntity.custSys.custSysCode, label: viewEntity.custSys.codeOrName}
          });
        }
        if (viewEntity.pageType) {
          setPageType(viewEntity.pageType);
        }
        setEntity(viewEntity);
        if (viewEntity.formSalesContract) {
          FormSalesContractTerms.getFormSalesContractTerms(viewEntity.formSalesContract.formSalesContractId).then(
            (response) => {
              if (viewEntity.createOrModify != 2) {
                response.data.forEach(item => {
                  item.salesContractTermsId = undefined;
                });
              }
              setDataSource(DataStore.init(response.data));
            },
          );
        } else {
          setDataSource(DataStore.init([]));
        }
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && pageType && entity) {
      let title = pageType.pageTypeName + '，合同创建';
      if (entity.createOrModify == 2) {
        title = pageType.pageTypeName + '，合同变更';
      }
      props.setPageTitle(title);
    }
  }, [props.setPageTitle, pageType, entity]);

  return (
    <>
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <ApplyFooter
                setLoading={props.setLoading}
                hiddenButton={[ApplyFooterType.Validate]}
                viewEntity={entity}
                submitBackUrl="/contract/formsalescontract"
                doSubmit={async (type: number, forceSubmit: boolean) => {
                  if (window.confirm("请确认合同申请所需附件已按要求提供")) {
                    if (!entity || !pageType || !dataSource) {
                      return;
                    }
                    const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                    if (values && values.errorFields && values.errorFields.length) {
                      return;
                    }
                    const statusId = type === ApplyFooterType.Draft ? Enum.FormStatus.DRAFT : Enum.FormStatus.AWAITING;
                    const dataResult = getPageResult(values, entity, pageType, statusId, dataSource);
                    if (dataResult.code !== ResponseStatus.SUCCESS) {
                      message.error(dataResult.message);
                      return;
                    }
                    props?.setLoading?.(true);

                    return await FormSalesContract.Buttons.apply({
                      ...dataResult.data
                    });
                  } else {
                    return;
                  }
                }}
                del={async () => {
                  if (!entity || !entity.formId) {
                    return;
                  }
                  return await FormSalesContract.Buttons.del(entity.formId);
                }}
              />
            );
          },
        }}
      >
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.BASE_INFO} title="基本信息"/>}>
          <Row gutter={[16, 24]}>
            {pageType != null &&
              (pageType.pageTypeId == Enum.PageType.DIRECT_SALES_CONTRACT
                || pageType.pageTypeId == Enum.PageType.INDIRECT_SALES_CONTRACT) &&
              (
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    name="custSysCode"
                    label="客户集团"
                    rules={[{required: true, message: '客户集团不能为空!'}]}
                  >
                    <WdiSelect
                      placeholder="请选择客户集团"
                      showSearch={true}
                      disabled={isEdit}
                      remote={true}
                      dataLoading={custSysDataLoading}
                      onChange={onCustSysSelectChange}
                    />
                  </Form.Item>
                </Col>
              )}
            <Col className="gutter-row" span={4}>
              <Form.Item
                name="soldToCode"
                label="售达方"
                rules={[{required: true, message: '售达方不能为空!'}]}
              >
                <WdiSelect
                  placeholder="请选择售达方"
                  showSearch={true}
                  disabled={isEdit}
                  remote={true}
                  dataLoading={soldToDataLoading}
                  reloadKey={selectCustSysCode}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                required
                name="contractDate"
                label="合同期间"
                rules={[{required: true, message: '合同期间不能为空!'}]}
              >
                <RangePicker picker={'month'} format={'YYYY-MM'}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="contractCode" label="合同编号">
                <Input/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="contractName" label="合同名称">
                <Input/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="owner" label="合同负责人">
                <Input/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="customerSignatory" label="客户方签约人">
                <Input/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="signDate" label="签约日期">
                <DatePicker/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} hidden={true}>
              <Form.Item name="isAutoAppend" label="是否自动费用追溯" valuePropName="checked">
                <Checkbox/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="isOfficial" label="计划/正式合同"
                         rules={[{required: true, message: '计划/正式合同不能为空!'}]}>
                <WdiSelect {...{
                  placeholder: "请选择计划/正式合同",
                  options: isOfficialOpts
                }} />
              </Form.Item>
            </Col>
            {isEdit && (<Col className="gutter-row" span={4}>
              <Form.Item name="tpmContractCode" label="TPM合同编号">
                <WdiInput readOnly style={salesContractId ? {
                  color: "#2299ee",
                  cursor: "pointer"
                } : {}} onClick={() => {
                  if (salesContractId) {
                    window.open(`/contract/SalesContractApproval/?salesContractId=${salesContractId}`);
                  }
                }}/>
              </Form.Item>
            </Col>)}
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="remark"
                label="合同备注"
              >
                <TextArea rows={4}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="ttForTs"
                label="需通过TP预提支付的合同费用"
              >
                <TextArea rows={4} placeholder={"包含但不限于违约金/缺货罚款/新店开业费/老店翻新费/促销员管理费及非按固定周期平均支付的固定金额合同费用"}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo"
                label="请按要求上传附件"
                tooltip={"合同计划/确认表中涉及主货架陈列，品牌产品推广活动，大型消费者活动冠名宣传，店内其他陈列/品牌曝光/品牌沟通/门店形象和APP线上资源曝光资源使用费需按照标准模板提交附件"}
                valuePropName="fileList"
                getValueFromEvent={(e: any) => {
                  return e.fileList || [];
                }}
              >
                <WdiFileUpload leftTopInfo="请选择文件并上传" multiple={true}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]} hidden={!isEdit}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="modifiedReason"
                label="修改原因"
                rules={[{required: isEdit, message: '修改原因不能为空!'}]}
              >
                <TextArea rows={4}/>
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.LIMITED_INFO} title='合同费用预测'/>}>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={4}>
              <Form.Item name="revenueForecast" label="预估销售额（不含税）" rules={[{required: true, message: '预估销售额不能为空!'}]}>
                <WdiInputNumber format='d' digits={2} min={0} onBlur={onRevenueForecastBlur}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="elnRateMax" label="ELN预估承担点数">
                <WdiInputNumber format='p' digits={4} readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="expenseForecast" label="ELN预估费用">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            {pageType != null &&
              pageType.pageTypeId == Enum.PageType.INDIRECT_SALES_CONTRACT &&
              (<Col className="gutter-row" span={4}>
                <Form.Item name="rateDealerMax" label="经销商承担点数">
                  <WdiInputNumber format='p' digits={4} readOnly/>
                </Form.Item>
              </Col>)}
          </Row>
        </WdiPanel>
        {pageType && (<SalesContractTermsDetails
          pageTypeId={pageType?.pageTypeId}
          getHeaderInfo={getHeaderInfo}
          dataSource={dataSource}
          onChange={onDetailsChange}
        />)}
      </ProForm>
    </>
  );
};
export default SalesContractApply;
