import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Tabs, message } from 'antd';
import ProForm, { ProFormInstance } from '@ant-design/pro-form';
import { FileUploadUtility, WdiFileList } from '@/components/WdiControls/WdiFileUpload';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import { FormPageProps, WdiFormPageTitle } from '@/components/WdiBus/FormPage';
import { ResponseStatus } from '@/utils/request';
import DataStore from '@/components/GridWidget/DataStore';
import { DataOptions } from '@/services/basic/metadata';
import { Enum } from '@/enums/system';
import DynamicInformation from './DynamicInformation';
import { WdiStaffCard, WdiTextArea } from '@/components/WdiControls';
import { OninvoiceReimbursementDetailsForApprovalPanel } from '../../OninvoiceReimbursementApply/components/detail';
import { ViewEntityProps, initApprovalPageContext } from '../../OninvoiceReimbursementApply/components/data';

const moreConfig: any = {
  successParams: [
    {
      url: "/basicapi/favorite/getButtons",
      keys: []
    }, {
      url: "/activityapi/rsButtons/get",
      keys: [
        // "UndoButton",
        // "ReeditButton",
        // "CancelButton",
        "InterveneFlowButton",
        // "ExportLockRemoveButton"
      ]
    }
  ],
  errorParams: [
    {
      url: "/activityapi/rsButtons/get",
      keys: [
        "RepairButton",
        "InterveneFlowButton"
      ]
    }
  ]
};

const approvalConfig: any = {
  params: {
    url: "/activityapi/rsButtons/get",
    keys: [
      "ApprovalButton"
    ]
  }
};

const OninvoiceReimbursementApprovalApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [entity, setEntity] = useState<ViewEntityProps>();

  useEffect(() => {
    (async () => {
      const params = props.data;

      if (params) {
        const result = await initApprovalPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        if (viewEntity.formRs) {
          formRef.current?.setFieldsValue({
            remark: viewEntity.formRs.remark,
            attachment: FileUploadUtility.parse(viewEntity.formRs.fileName, viewEntity.formRs.filePath)
          });
        }

        if (viewEntity.formRsDetails) {
          setDataSource(DataStore.init(viewEntity.formRsDetails));
        }

        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `on-invoice合同返利核销单【${entity.form?.formNo}】`;
      DataOptions.search({ typeGroup: 3, value: entity.form?.statusId }).then(response => {
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (response.data && response.data.length > 0) {
          title += `，${response.data[0].name}`;
          if (props.setPageTitle) props.setPageTitle(
            <WdiFormPageTitle
              title={title}
              onClose={props.onClose}
              callback={props.callback}
              form={entity?.form}
              useCaseId={Enum.UseCase.RSP}
              moreConfig={moreConfig}
              approvalConfig={approvalConfig} />
          );
        }
      });
    }
  }, [props.setPageTitle, entity]);

  return (
    <>
      <WdiStaffCard formId={entity?.form?.formId} />
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab={<span>核销信息</span>} key="1">
          <ProForm
            formRef={formRef}
            submitter={{
              render: () => { return (<></>) },
            }}
          >
            <Row gutter={[16, 24]}>
              <Col className="gutter-row" span={16}>
                <Form.Item name="remark" label="核销备注" >
                  <WdiTextArea rows={4} readOnly />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  name="attachment"
                  label="核销附件"
                  valuePropName='fileList'
                  getValueFromEvent={(e: any) => { return e.fileList || []; }}
                >
                  <WdiFileList />
                </Form.Item>
              </Col>
            </Row>
          </ProForm>
          <OninvoiceReimbursementDetailsForApprovalPanel dataSource={dataSource} />
        </TabPane>
        <TabPane tab={<span>动态信息</span>} key="2">
          <DynamicInformation formId={entity?.form?.formId} formTypeId={entity?.form?.formTypeId} pageTypeId={entity?.form?.pageTypeId} useCaseId={Enum.UseCase.RSP} />
        </TabPane>
      </Tabs>

    </>
  );
};
export default OninvoiceReimbursementApprovalApp;
