import {wdiCore} from '@/components/WdiControls';
import {WdiInputNumber} from '@/components/WdiControls/WdiInputNumber';
import {Col, Row} from 'antd';
import {responseResultError, responseResultOk} from "@/utils/response";
import {useEffect} from "react";

export const ColumnType = {
  //坎级金额
  MinValue1: 1,
  //坎级听数
  MinValue2: 2,
  //结案比例
  StandardValue1: 3,
  //单位费用（听）
  StandardValue2: 4,
};

type SCTRateGridHeaderProps = {};
const headerStyle: React.CSSProperties = {
  textAlign: 'left',
  height: '35px',
  lineHeight: '35px',
  paddingLeft: '2px',
  border: '1px solid #DDDDDD',
};
const SCTRateGridHeader: React.FC<SCTRateGridHeaderProps> = (props: SCTRateGridHeaderProps) => {
  return (
    <Row style={{border: '1px solid #DDDDDD', borderCollapse: 'collapse'}}>
      <Col span={6} style={headerStyle}>坎级金额</Col>
      <Col span={6} style={headerStyle}>坎级听数</Col>
      <Col span={6} style={headerStyle}>结案比例(%)</Col>
      <Col span={6} style={headerStyle}>单听费用</Col>
    </Row>
  );
};

type SCTRateGridRowValue = {
  isQuantity?: boolean;
  value?: number;
  standardValue?: number;
};

type SCTRateGridRowProps = {
  rowIndex: number;
  readonly?: boolean;
  value?: SCTRateGridRowValue;
  onChange: (columnType: number, rowIndex: number, v: number | null) => void;
  isQuantity?: boolean;
};

const rowStyle: React.CSSProperties = {
  textAlign: 'left',
  height: '35px',
  lineHeight: '35px',
  paddingLeft: '1px',
  border: '1px solid #DDDDDD',
};

const isNullOrEmpty = (value: any) => {
  if (value === null || value === undefined) {
    return true;
  }
  if (value === "") {
    return true;
  }
  return false;
};

const SCTRateGridRow: React.FC<SCTRateGridRowProps> = (props: SCTRateGridRowProps) => {
  return (
    <Row>
      <Col span={6} style={rowStyle}>
        <WdiInputNumber
          readOnly={!!props.readonly || props.isQuantity || props.isQuantity == null}
          min={0}
          value={(props.isQuantity || props.isQuantity == null) ? undefined : props.value?.value}
          onChange={
            (value: any) => {
              if (!wdiCore.Object.IsNullOrUndefined(value) && !wdiCore.String.IsNullOrEmpty(value)) {
                props.onChange(ColumnType.MinValue1, props.rowIndex, wdiCore.Number.ToNumber(value, 2));
              } else {
                props.onChange(ColumnType.MinValue1, props.rowIndex, null);
              }
            }
          }
        />
      </Col>
      <Col span={6} style={rowStyle}>
        <WdiInputNumber
          readOnly={!!props.readonly || !props.isQuantity || props.isQuantity == null}
          min={0}
          value={(!props.isQuantity || props.isQuantity == null) ? undefined : props.value?.value}
          onChange={
            (value: any) => {
              if (!wdiCore.Object.IsNullOrUndefined(value) && !wdiCore.String.IsNullOrEmpty(value)) {
                props.onChange(ColumnType.MinValue2, props.rowIndex, wdiCore.Number.ToNumber(value, 2));
              } else {
                props.onChange(ColumnType.MinValue2, props.rowIndex, null);
              }
            }
          }
        />
      </Col>
      <Col span={6} style={rowStyle}>
        <WdiInputNumber
          readOnly={!!props.readonly || props.isQuantity || props.isQuantity == null}
          format="p"
          digits={4}
          min={0}
          value={(props.isQuantity || props.isQuantity == null) ? undefined : props.value?.standardValue}
          onChange={
            (value: any) => {
              if (!wdiCore.Object.IsNullOrUndefined(value) && !wdiCore.String.IsNullOrEmpty(value)) {
                props.onChange(ColumnType.StandardValue1, props.rowIndex, wdiCore.Number.ToNumber(value, 6));
              } else {
                props.onChange(ColumnType.StandardValue1, props.rowIndex, null);
              }
            }
          }
        />
      </Col>
      <Col span={6} style={rowStyle}>
        <WdiInputNumber
          readOnly={!!props.readonly || !props.isQuantity || props.isQuantity == null}
          min={0}
          value={(!props.isQuantity || props.isQuantity == null) ? undefined : props.value?.standardValue}
          onChange={
            (value: any) => {
              if (!wdiCore.Object.IsNullOrUndefined(value) && !wdiCore.String.IsNullOrEmpty(value)) {
                props.onChange(ColumnType.StandardValue2, props.rowIndex, wdiCore.Number.ToNumber(value, 2));
              } else {
                props.onChange(ColumnType.StandardValue2, props.rowIndex, null);
              }
            }
          }
        />
      </Col>
    </Row>
  );
};

type SCTRateGridValue = {
  isQuantity?: boolean;
  items: SCTRateGridRowValue [];
};

type SCTRateGridProps = {
  readonly?: boolean;
  value?: SCTRateGridValue;
  onChange?: (value?: SCTRateGridValue) => void;
  isQuantity?: boolean;
};

const SCTRateGrid: React.FC<SCTRateGridProps> = (props: SCTRateGridProps) => {
  const onValueChange = (columnType: number, rowIndex: number, v: number | null) => {
    const value: SCTRateGridValue = {
      isQuantity: props.isQuantity,
      items: []
    };
    props.value?.items?.forEach((item, itemIndex) => {
      let result = item;
      if (itemIndex === rowIndex) {
        if (columnType == ColumnType.MinValue1) {
          result.value = v
        }
        if (columnType == ColumnType.MinValue2) {
          result.value = v
        }
        if (columnType == ColumnType.StandardValue1) {
          result.standardValue = v
        }
        if (columnType == ColumnType.StandardValue2) {
          result.standardValue = v
        }
      }
      value.items.push(result);
    });
    value.isQuantity = props.isQuantity;
    props.onChange?.(value);
  };

  useEffect(() => {
    const {...v} = Object.assign({}, props.value);
    if (v) {
      if (props.isQuantity) {
        v.isQuantity = props.isQuantity;
      }
      props?.onChange?.(v);
    }
  }, [props.isQuantity]);
  return (
    <>
      <SCTRateGridHeader/>
      {
        props.value &&
        props.value.items &&
        props.value.items?.map((item, itemIndex) => (
          <SCTRateGridRow
            key={itemIndex}
            rowIndex={itemIndex}
            readonly={props.readonly}
            value={item}
            onChange={onValueChange}
            isQuantity={props.isQuantity}
          />
        ))
      }
    </>
  );
};

const res = {
  rowNumberP1: (rowNumber: number) => {
    return "第" + rowNumber + "行，"
  },
  criticalMust0: "坎级金额/数量必须为0",
  criticalOnly1: "坎级值不能为空",
  resultOnly1: "结案比例/单位费用必须填写1个",
  rebateRateMust0_1: "结案比例必须在0～1之间",
  unitFeeMustGt0: "单位费用必须为正数",
  criticalMustNumber: "坎级值必须为数字",
  currentMustGtLast: "坎级金额/坎级数量要大于上一行的",
};

const utils = {
  value: (target: API.FormSalesContractRate, source: SCTRateGridRowValue) => {
    target.value = source.value;
  },
  result: (target: API.FormSalesContractRate, source: SCTRateGridRowValue) => {
    target.standardValue = source.standardValue;
  }
};

const initSalesContractRateGrid = (revenueTypeId: number, values?: API.FormSalesContractRate[]) => {
  const rows: SCTRateGridRowValue[] = [];
  let isQuantity: boolean;
  if (revenueTypeId == 3 || revenueTypeId == 5) {
    isQuantity = true;
  } else if (revenueTypeId == 4 || revenueTypeId == 6) {
    isQuantity = false;
  }
  if (values) {
    values.forEach((item) => {
      const row: SCTRateGridRowValue = {
        value: item.value,
        standardValue: item.standardValue,
        isQuantity: isQuantity
      };
      rows.push(row);
    });
  }
  // 返利标准必须是5行
  for (let i = rows.length; i < 5; i++) {
    if (i == 0) {
      rows.push({value: 0});
    } else {
      rows.push({});
    }
  }
  return {
    items: rows
  };
};

const initRows = (rateGridValue?: SCTRateGridValue) => {
  const rows: any[] = [];
  if (rateGridValue?.items) {
    const stantdardName = "standardValue";
    const criticalName = "value";
    const isQuantity = "isQuantity";
    rateGridValue?.items?.forEach((item: any, index) => {
      const obj: any = {};
      obj[stantdardName] = item[stantdardName];
      obj[criticalName] = item[criticalName];
      obj[isQuantity] = rateGridValue.isQuantity;
      rows.push(obj);
    })
  }
  return rows;
}

const convertToResult = (rateGridValue?: SCTRateGridValue) => {
  const rows = initRows(rateGridValue);
  const values: API.FormSalesContractRate[] = [];
  if (rows && rows.length) {
    let currentRow = rows[0];
    let currentValue: API.FormSalesContractRate = {};

    if (!isNullOrEmpty(currentRow.value)) {
      if (currentRow.value != 0) {
        return responseResultError(res.rowNumberP1(1) + res.criticalMust0);
      }
      utils.value(currentValue, currentRow);
    } else {
      return responseResultError(res.rowNumberP1(1) + res.criticalOnly1);
    }

    if (!isNullOrEmpty(currentRow.standardValue)) {
      if (!currentRow.isQuantity && (currentRow.standardValue < 0 || 1 < currentRow.standardValue)) {
        return responseResultError(res.rowNumberP1(1) + res.rebateRateMust0_1);
      } else if (currentRow.standardValue < 0) {
        return responseResultError(res.rowNumberP1(1) + res.unitFeeMustGt0);
      }
      utils.result(currentValue, currentRow);
    } else {
      return responseResultError(res.rowNumberP1(1) + res.resultOnly1);
    }

    values.push(currentValue);

    let lastValue: API.FormSalesContractRate = currentValue;
    for (let i = 1; i < rows.length; i++) {
      currentRow = rows[i];
      currentValue = {};

      if (isNullOrEmpty(currentRow.standardValue)) {
        break;
      }
      if (!currentRow.isQuantity && (currentRow.standardValue < 0 || 1 < currentRow.standardValue)) {
        return responseResultError(res.rowNumberP1(i + 1) + res.rebateRateMust0_1);
      } else if (currentRow.standardValue < 0) {
        return responseResultError(res.rowNumberP1(1) + res.unitFeeMustGt0);
      }
      utils.result(currentValue, currentRow);

      if (isNullOrEmpty(currentRow.value)) {
        return responseResultError(res.rowNumberP1(i + 1) + res.resultOnly1)
      }
      utils.value(currentValue, currentRow);

      if (lastValue.value >= currentValue.value) {
        return responseResultError(res.rowNumberP1(i + 1) + res.currentMustGtLast)
      }
      values.push(currentValue);
      lastValue = currentValue;
    }
  }
  return responseResultOk({data: values});
};

export {convertToResult, initSalesContractRateGrid, SCTRateGrid};
