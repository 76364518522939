import {formatter, request} from "@/utils/request";

export namespace FormSalesContract {
  const Path = {
    Query: '/reportapi/contract/queryForFormSalesContract',
    Get: '/contractapi/formSalesContract/get',
    GetHistory: '/contractapi/formSalesContract/queryForSalesContractHistory',
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.QueryResult<any>>>(Path.Query, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.FormSalesContract>>(Path.Get, formatter(options));
  }

  export async function getHistory(salesContractId: number) {
    const options = {
      method: 'GET',
      body: {
        salesContractId: salesContractId
      }
    };

    return request<API.ResponseResult<any>>(Path.GetHistory, formatter(options));
  }


  export namespace Buttons {
    export const Path = {
      ValidateDetail: '/contractapi/buttons/validateDetail',
      Apply: '/contractapi/buttons/apply',
      Delete: '/contractapi/buttons/delete',
      DynamicInfos: '/contractapi/buttons/dynamicInfos',
      GetFormSalesContractTermsCompareCollections: '/contractapi/buttons/getFormSalesContractTermsCompareCollections',
    };

    export async function validateDetail(body: {
      rowIndex: number,
      formSalesContract: any,
      formSalesContractTerms: any,
      formSalesContractTermsList: any[],
    }) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<API.FormSalesContractTermsVo>>(Path.ValidateDetail, formatter(options));
    };

    export async function apply(body: { form: any, formSalesContract: any, formSalesContractDetails: any[] }) {
      const options = {
        method: 'POST',
        body: body
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.Apply, formatter(options));
    };

    export async function del(formId: number) {
      const options = {
        method: 'POST',
        body: {formId: formId}
      };
      return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
    };

    export async function dynamicInfos(formId: number, formType: number, pageType: number) {
      const options = {
        method: 'Get',
        body: {formId}
      };
      return request<API.DynamicInformation[]>(Path.DynamicInfos, formatter(options));
    };

    export function getFormSalesContractTermsCompareCollections(body: { formId: any; lastFormSalesContractId: any }) {
      const options = {
        method: 'GET',
        body: {
          ...body
        },
      };
      return request<API.ResponseResult<API.FormSalesContractTermsCompareCollections>>(Path.GetFormSalesContractTermsCompareCollections, formatter(options));
    }
  }
}

