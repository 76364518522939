import {useEffect, useState} from 'react';
import {DetailGrid} from '@/components/GridWidget';
import {NsGrid} from '@/components/GridWidget/GridDefProps';
import {WdiCardTitle, WdiModalProps} from '@/components/WdiControls';
import {initFieldDisplayFormater} from '@/components/WdiBus/TableColumnRanderFormats'
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {Enum} from '@/enums/system';
import {GridApi} from '@/services/gridapi';
import {isInDirectContract} from "@/pages/contract/SalesContract/components/data";
import {Button, Space} from "antd";
import FormSalesContractTermsCompareModalApp
  from "@/pages/contract/FormSalesContractApproval/components/FormSalesContractTermsCompare";

export type DetailForApprovalProps = {
  data?: {
    form?: API.Form,
    formSalesContract?: API.FormSalesContract,
  },
  pageTypeId?: number;
  getHeaderInfo?: () => any;
  dataSource?: any[];
  onChange?: (dataSource: any) => void;
};

const SalesContractTermsForApproval: React.FC<DetailForApprovalProps> = (props) => {
  // Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const {pageTypeId, dataSource} = props;

  useEffect(() => {
    var formDefID = pageTypeId == Enum.PageType.INDIRECT_SALES_CONTRACT ? "SalesContractTerms2ForApproval" : "SalesContractTermsForApproval";
    GridApi.searchConfig({id: formDefID, allowModifyColumns: true}).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        rowKey: 'uid',
        actionsAlign: NsGrid.ActionsAlign.None,
        showExpandedRow: true,
      });
      settings.formSchema.allFields.rowNumber.getDisplayFormater = (_t: any, record: any, index: any) => {
        return `${record.rowNumber || index + 1}`;
      };
      initFieldDisplayFormater(settings.formSchema.allFields);
      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.processModeName.width = 100;
      settings.formSchema.allFields.revenueTypeName.width = 160;
      settings.formSchema.allFields.productNamesRebate.width = 250;
      settings.formSchema.allFields.settlementFrequencyName.width = 80;
      settings.formSchema.allFields.termsDesc.width = 180;

      settings.formSchema.allFields.rateOriginal.width = 120;
      settings.formSchema.allFields.productNamesReach.width = 100;
      settings.formSchema.allFields.remark.width = 100;
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);
  let maxAllowedFields = ['productNamesReach', 'rateOriginal', 'quantityForecast', 'termsAmount', 'remark'];
  if (pageTypeId == Enum.PageType.INDIRECT_SALES_CONTRACT) {
    maxAllowedFields = ['productNamesReach', 'rateOriginal', 'quantityForecast', 'termsAmount', 'rateDealer', 'termsAmountDealer', 'remark'];
  }
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return maxAllowedFields;
    },
  };

  return (
    <WdiPanel collapsed={true} title={<WdiCardTitle iconType={Enum.IconType.CONTRACT_TERM} title='合同条款'/>}>
      {
        isLoaded &&
        <DetailGrid {...pageGridSettings} autoColumn={['rowNumber']}/>
      }
    </WdiPanel>
  );
};
export default SalesContractTermsForApproval;

export const SalesContractTermsForApprovalPanel: React.FC<DetailForApprovalProps> = (props) => {

  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false});

  useEffect(() => {
    const formDefID = isInDirectContract(props.pageTypeId) ? 'FormSalesContractTerms2' : 'FormSalesContractTerms';
    GridApi.searchConfig({id: formDefID, allowModifyColumns: true}).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.None,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.rowNumber.getDisplayFormater = (_t: any, record: any, index: any) => {
        return `${record.rowNumber || index + 1}`;
      };

      settings.formSchema.allFields.rowNumber.width = 50;
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  let maxAllowedFields = ['productNamesReach', 'rateOriginal', 'quantityForecast', 'revenueForecast', 'termsAmount', 'remark'];
  if (isInDirectContract(props.pageTypeId)) {
    maxAllowedFields = ['productNamesReach', 'rateOriginal', 'quantityForecast', 'revenueForecast', 'termsAmount', 'rateDealer', 'termsAmountDealer', 'remark'];
  }
  const {dataSource, data} = props;
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return maxAllowedFields;
    },
  };

  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='合同条款'/>} extra={
      <Space>
        <Button
          hidden={data?.formSalesContract?.createOrModify != 2}
          onClick={(e) => {
            e.stopPropagation();
            setModalProps({
              title: "合同变更对比",
              data: {form: data?.form, formSalesContract: data?.formSalesContract}, ...modalProps, ...{visible: true}
            });
          }}
          type='primary'>
          变更对比
        </Button>
      </Space>
    }>
      {isLoaded
        && modalProps.visible
        && data?.formSalesContract
        && <FormSalesContractTermsCompareModalApp {...modalProps} onClose={() => {
          setModalProps({...modalProps, ...{visible: false}});
        }}/>}
      {isLoaded && <DetailGrid {...pageGridSettings} />}
    </WdiPanel>
  );
};

