import React, {useEffect, useRef} from "react";
import {WdiModalAppProps} from "@/components/WdiControls";
import {ProFormInstance} from "@ant-design/pro-form";
import {ResponseStatus} from "@/utils/request";
import {Checkbox, Form, message} from "antd";
import {WdiModalForm} from "@/components/WdiControls/WdiModalForm";
import {SalesContract} from "@/services/contract/salescontract";

const IsAutoAppendEdit: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  useEffect(() => {
    if (props.id) {
      const salesContractId = Number(props.id)
      formRef.current?.setFieldsValue({
        salesContractId: salesContractId,
      })
      SalesContract.get(salesContractId).then(res => {
        formRef.current?.setFieldsValue({
          isAutoAppend: res?.data.isAutoAppend
        });
      })
    }

  }, [props.id])
  return (
    <>
      <WdiModalForm
        formRef={formRef}
        labelCol={{span: 6}}
        wrapperCol={{span: 12}}
        labelAlign='left'
        layout="horizontal"
        title={props.title}
        visible={props.visible}
        modalProps={{
          onCancel: () => {
            props.onClose ? props.onClose() : null
          },
          destroyOnClose: true,
          maskClosable: false
        }}
        onFinish={async (values) => {
          const {...entity} = values;
          const response = await SalesContract.modifyIsAutoAppend(entity);
          if (response.code != ResponseStatus.SUCCESS) {
            message.error(response.message);
            return false;
          }
          message.success('修改成功!');
          if (props.onSave) {
            props.onSave();
          }
          return true;
        }}
      >
        <Form.Item name='salesContractId' hidden={true}>
        </Form.Item>
        <Form.Item name="isAutoAppend" label="是否自动费用追溯" valuePropName="checked">
          <Checkbox/>
        </Form.Item>
      </WdiModalForm>
    </>
  )
}
export default IsAutoAppendEdit;
