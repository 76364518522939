import type {ReactNode} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import ReactDOM from 'react-dom';
import {Utility} from '@/components/GridWidget';
import {Enum} from '@/enums/system';
import FormBudgetProjectApprovalApp from '@/pages/project/GeneralProjectApproval/components/approval';
import FormBudgetProjectApplyApp from '@/pages/project/GeneralProjectApply/components/apply';
import type {WdiModalAppResult} from '../WdiControls';
import GeneralPaymentApplyApp from '@/pages/sales/sp/GeneralPaymentApply/components/apply';
import GeneralSettleApplyApp from '@/pages/sales/ss/GeneralSettleApply/components/apply';
import FreeGoodsApplyApp from '@/pages/sales/sa/FreeGoodsApply/components/apply';
import BudgetProjectDisplayApp from '@/pages/project/BudgetProject/components/BudgetProjectDisplay';
import GeneralActivityApplyApp from '@/pages/sales/sa/GeneralActivityApply/components/apply';
import GeneralActivityApprovalApp from '@/pages/sales/sa/GeneralActivityApproval/components/approval';
import DealerActivityApplyApp from '@/pages/sales/sa/DealerActivityApply/components/apply';
import DealerActivityApprovalApp from '@/pages/sales/sa/DealerActivityApproval/components/approval';
import GeneralSettleApprovalApp from '@/pages/sales/ss/GeneralSettleApproval/components/approval';
import GeneralPaymentApprovalApp from '@/pages/sales/sp/GeneralPaymentApproval/components/approval';
import RebatePaymentApplyApp from '@/pages/contract/RebatePaymentApply/components/apply';
import RebatePaymentApprovalApp from '@/pages/contract/RebatePaymentApproval/components/approval';
import FGActivityApprovalApp from '@/pages/sales/sa/FreeGoodsApproval/components/approval';
import {GlobalPromptInstance} from '../WdiControls/WdiPromptContext';
import SalesContractApproval from '@/pages/contract/SalesContractApproval/components/approval';
import SalesContractApply from '@/pages/contract/SalesContractApply/components/apply';
import {WdiDrawer} from '../WdiControls/WdiDrawer';
import {ConfigProvider, message, Space, Spin} from 'antd';
import {ApprovalButton, MoreWapper, WdiButtonContainer} from './Buttons';
import PriceDiscountApplyApp from '@/pages/sales/sa/PriceDiscountApply/components/apply';
import {FormApi} from '@/services/sales';
import {ResponseStatus} from '@/utils/request';
import PriceDiscountApprovalApp from '@/pages/sales/sa/PriceDiscountApproval/components/approval';
import zhCN from 'antd/lib/locale/zh_CN';
import {ResponseCode} from '@/utils/response';
import OninvoicePaymentApplyApp from '@/pages/contract/OninvoicePaymentApply/components/apply';
import OninvoicePaymentApprovalApp from '@/pages/contract/OninvoicePaymentApproval/components/approval';
import PoolPrepayReverseApply from "@/pages/financial/PoolPrepayReverseApply/components/apply";
import PoolPrepayReverseApproval from "@/pages/financial/PoolPrepayReverseApproval/components/approval";
import RebateReimbursementApplyApp from '@/pages/contract/RebateReimbursementApply/components/apply';
import OninvoiceReimbursementApplyApp from '@/pages/contract/OninvoiceReimbursementApply/components/apply';
import RebateReimbursementApprovalApp from '@/pages/contract/RebateReimbursementApproval/components/approval';
import OninvoiceReimbursementApprovalApp from '@/pages/contract/OninvoiceReimbursementApproval/components/approval';
import SoldToSettleApplyApp from "@/pages/sales/ss/SoldToSettleApply/components/apply";
import SoldToSettleApprovalApp from "@/pages/sales/ss/SoldToSettleApproval/components/approval";
import FormSalesContractApprovalApp from "@/pages/contract/FormSalesContractApproval/components/approval";

export enum PageMode {
  Apply = 1,
  Approval = 2,
  Modify = 3,
};

export enum ModuleType {
  Form = 1,
  Project = 2,
  Contract = 3,
  Policy = 4,
  PoolPrepayReverse = 5,
};

export enum OpenMode {
  Page = 1,
  Drawer = 2
}

export type ParamsProps = {
  formId?: number,
  relatedFormId?: number,
  relatedType?: number,
  pageTypeId?: number,
  soldToMasterCode?: string,
  year?: number,
  prepayType?: number,
  [key: string]: any;
}

export type FormPageProps = {
  id?: number | string;
  pageTypeId?: number;
  formTypeId?: number;
  bohqId?: number;
  data?: ParamsProps;
  mode?: PageMode;
  openMode?: OpenMode;
  moduleType?: ModuleType;
  key?: string;
  children?: React.ReactNode;
  callback?: (result?: WdiModalAppResult) => void;
  setPageTitle?: (title?: ReactNode) => void;
  onClose?: (result?: WdiModalAppResult) => void;
  setLoading?: (loading: boolean) => void;
  /**关闭确认函数，用来代替默认的 window.confirm */
  onCloseConfirm?: () => boolean;
  /**关闭确认信息 */
  closeConfirmMessage?: () => string;
  /**禁用关闭确认,这个是优先级最高的判断 */
  disableCloseConfirm?: boolean;
}

export interface PageControl {
  show: (props: FormPageProps) => void;
  apply: (props: FormPageProps) => void;
  approval: (props: FormPageProps) => void;
}

const WdiPageDrawer: React.FC<FormPageProps> = (props: FormPageProps) => {
  const [visible, setVisible] = useState<boolean>(true);
  const [title, setTitle] = useState<ReactNode>();
  const [loading, setLoading] = useState(false);
  const {onCloseConfirm, disableCloseConfirm, closeConfirmMessage, ...newProps} = props;

  const promptMessage = () => closeConfirmMessage?.() || '系统不会保存您所做的更改，确定要关闭此界面吗？';
  const hasConfirm = disableCloseConfirm
    ? false
    : (null != onCloseConfirm || props.mode === PageMode.Apply || props.mode === PageMode.Modify);
  useEffect(
    () => {
      if (visible) {
        GlobalPromptInstance.addPageChangeListener(closeDrawer);
        if (hasConfirm) {
          GlobalPromptInstance.setEnable(true);
          GlobalPromptInstance.setPromptMessage(promptMessage);
        }
      }
    },
    []
  );
  const closeDrawer = () => {
    setVisible(false);
    GlobalPromptInstance.popPageChangeListener();
    if (hasConfirm) {
      GlobalPromptInstance.setEnable(false);
      GlobalPromptInstance.popPromptMessage();
    }
  }

  const onClose = (result?: WdiModalAppResult) => {
    if (disableCloseConfirm
      || onCloseConfirm?.()
      || (props.mode !== PageMode.Apply && props.mode !== PageMode.Modify)
      || result?.code == ResponseCode.SUCCESS
      || window.confirm(promptMessage())
    ) {
      closeDrawer();
    }
  }
  const setPageTitle = useMemo(() => {
    return (newTitle?: ReactNode) => {
      setTitle(newTitle)
    };
  }, []);
  const onLoading = (loading: boolean) => {
    setLoading(loading);
  }

  newProps.setLoading = onLoading;
  newProps.setPageTitle = setPageTitle;
  newProps.onClose = onClose;
  const Component = getComp(Number(props.pageTypeId), Number(props.formTypeId), Number(props.data?.policyType), props.mode, props.moduleType);
  return (
    <WdiDrawer
      headerStyle={{padding: '16px 24px'}}
      bodyStyle={{padding: '0 32px', paddingBottom: 20}}
      onClose={() => {
        onClose()
      }}
      title={title}
      visible={visible}
    >
      {/* 本项目没有中英文，默认写死中文，若配置国际化，需要使用getLocale()获取当前语言判断使用哪个 */}
      <ConfigProvider locale={zhCN}>
        <Spin spinning={loading && (props.mode == PageMode.Apply || props.mode == PageMode.Modify)}
              size='large'>
          <Component {...newProps} />
        </Spin>
      </ConfigProvider>
    </WdiDrawer>
  );
}

const Empty: React.FC<FormPageProps> = (props: FormPageProps) => {
  useEffect(() => {
    if (props.setPageTitle) {
      props.setPageTitle('无效的单据');
    }
  }, [props.setPageTitle]);
  return (
    <></>
  )
}
export const getFormUrlMapping = (pageTypeId: number, formTypeId: number) => {
  if (formTypeId === Enum.FormType.PROJECT_APPLICATION) {
    if (pageTypeId === Enum.PageType.PROJECT_GENERAL) {
      return '/project/GeneralProjectApproval/';
    }
  } else if (formTypeId === Enum.FormType.ACTIVITY_APPLICATION) {
    if (pageTypeId === Enum.PageType.ACTIVITY_APPLICATION) {
      return '/sales/sa/GeneralActivityApproval/';
    } else if (pageTypeId === Enum.PageType.PRICE_DISCOUNT_APPLICATION) {
      return '/sales/sa/PriceDiscountApproval/';
    } else if (pageTypeId === Enum.PageType.FREE_GOODS_APPLICATION) {
      return '/sales/sa/FreeGoodsApproval/';
    } else if (pageTypeId === Enum.PageType.ACTIVITY_APPLICATION_DEALER) {
      return '/sales/sa/DealerActivityApproval';
    }
  } else if (formTypeId === Enum.FormType.ACTIVITY_SETTLEMENT) {
    if (pageTypeId === Enum.PageType.ACTIVITY_SETTLEMENT) {
      return '/sales/ss/GeneralSettleApproval/';
    } else if (pageTypeId === Enum.PageType.ACTIVITY_SETTLEMENT_DEALER) {
      return '/sales/ss/SoldToSettleApproval/'
    }
  } else if (formTypeId === Enum.FormType.ACTIVITY_PAYMENT) {
    if (pageTypeId === Enum.PageType.PAYMENT_CUST_SYS || pageTypeId === Enum.PageType.PAYMENT_SOLD_TO) {
      return '/sales/sp/GeneralPaymentApproval';
    }
  } else if (formTypeId === Enum.FormType.CONTRACT_REBATE_SETTLEMENT) {
    if (pageTypeId === Enum.PageType.CONTRACT_REBATE_REIMBURSEMENT) {
      return '/contract/RebateReimbursementApproval/';
    } else if (pageTypeId === Enum.PageType.CONTRACT_REBATE_REIMBURSEMENT_ONINVOICE) {
      return '/contract/OninvoiceReimbursementApproval/';
    }
  } else if (formTypeId === Enum.FormType.CONTRACT_REBATE_PAYMENT) {
    if (pageTypeId === Enum.PageType.PAYMENT_CONTRACT_REBATE) {
      return '/contract/RebatePaymentApproval/';
    } else if (pageTypeId === Enum.PageType.PAYMENT_CONTRACT_ONINVOICE) {
      return '/contract/OninvoicePaymentApproval/';
    }
  } else if (formTypeId === Enum.FormType.SALES_CONTRACT) {
    if (pageTypeId === Enum.PageType.DIRECT_SALES_CONTRACT || pageTypeId === Enum.PageType.DEALER_SALES_CONTRACT || pageTypeId === Enum.PageType.INDIRECT_SALES_CONTRACT) {
      return '/contract/FormSalesContractApproval';
    }
  }
  return '';
}
const getComp = (pageTypeId: number, formTypeId: number, policyTypeId: number, mode?: PageMode, moduleType?: ModuleType) => {
  if (ModuleType.Project === moduleType) {
    return BudgetProjectDisplayApp;
  } else if (ModuleType.Contract === moduleType) {
    if (mode === PageMode.Apply) {
      return SalesContractApply;
    } else if (mode === PageMode.Approval) {
      return SalesContractApproval;
    }
  } else if (ModuleType.Form === moduleType) {
    if (mode === PageMode.Approval) {
      if (formTypeId === Enum.FormType.PROJECT_APPLICATION) {
        if (pageTypeId === Enum.PageType.PROJECT_GENERAL) {
          return FormBudgetProjectApprovalApp;
        }
      } else if (formTypeId === Enum.FormType.ACTIVITY_APPLICATION) {
        if (pageTypeId === Enum.PageType.FREE_GOODS_APPLICATION) {
          return FGActivityApprovalApp;
        } else if (pageTypeId === Enum.PageType.ACTIVITY_APPLICATION) {
          return GeneralActivityApprovalApp;
        } else if (pageTypeId === Enum.PageType.PRICE_DISCOUNT_APPLICATION) {
          return PriceDiscountApprovalApp;
        } else if (pageTypeId === Enum.PageType.ACTIVITY_APPLICATION_DEALER) {
          return DealerActivityApprovalApp;
        }
      } else if (formTypeId === Enum.FormType.ACTIVITY_SETTLEMENT) {
        if (pageTypeId === Enum.PageType.ACTIVITY_SETTLEMENT) {
          return GeneralSettleApprovalApp;
        } else if (pageTypeId === Enum.PageType.ACTIVITY_SETTLEMENT_DEALER) {
          return SoldToSettleApprovalApp;
        }
      } else if (formTypeId === Enum.FormType.CONTRACT_REBATE_SETTLEMENT) {
        if (pageTypeId === Enum.PageType.CONTRACT_REBATE_REIMBURSEMENT) {
          return RebateReimbursementApprovalApp;
        } else if (pageTypeId === Enum.PageType.CONTRACT_REBATE_REIMBURSEMENT_ONINVOICE) {
          return OninvoiceReimbursementApprovalApp;
        }
      } else if (formTypeId === Enum.FormType.CONTRACT_REBATE_PAYMENT) {
        if (pageTypeId === Enum.PageType.PAYMENT_CONTRACT_REBATE) {
          return RebatePaymentApprovalApp;
        } else if (pageTypeId === Enum.PageType.PAYMENT_CONTRACT_ONINVOICE) {
          return OninvoicePaymentApprovalApp;
        }
      } else if (formTypeId === Enum.FormType.ACTIVITY_PAYMENT) {
        if (pageTypeId === Enum.PageType.PAYMENT_CUST_SYS || pageTypeId === Enum.PageType.PAYMENT_SOLD_TO) {
          return GeneralPaymentApprovalApp;
        }
      } else if (formTypeId === Enum.FormType.SALES_CONTRACT) {
        return FormSalesContractApprovalApp;
      }
    } else if (mode === PageMode.Apply) {
      if (formTypeId === Enum.FormType.PROJECT_APPLICATION) {
        if (pageTypeId === Enum.PageType.PROJECT_GENERAL) {
          return FormBudgetProjectApplyApp;
        }
      } else if (formTypeId === Enum.FormType.SALES_CONTRACT) {
        return SalesContractApply;
      } else if (formTypeId === Enum.FormType.ACTIVITY_APPLICATION) {
        if (pageTypeId === Enum.PageType.FREE_GOODS_APPLICATION) {
          return FreeGoodsApplyApp;
        } else if (pageTypeId === Enum.PageType.ACTIVITY_APPLICATION) {
          return GeneralActivityApplyApp;
        } else if (pageTypeId === Enum.PageType.PRICE_DISCOUNT_APPLICATION) {
          return PriceDiscountApplyApp;
        } else if (pageTypeId === Enum.PageType.ACTIVITY_APPLICATION_DEALER) {
          return DealerActivityApplyApp;
        }
      } else if (formTypeId === Enum.FormType.ACTIVITY_SETTLEMENT) {
        if (pageTypeId === Enum.PageType.ACTIVITY_APPLICATION || pageTypeId === Enum.PageType.ACTIVITY_SETTLEMENT) {//'客户投资类'
          return GeneralSettleApplyApp;
        } else if (pageTypeId === Enum.PageType.ACTIVITY_APPLICATION_DEALER || pageTypeId === Enum.PageType.ACTIVITY_SETTLEMENT_DEALER) {//'经销商投资类'
          return SoldToSettleApplyApp;
        }
      } else if (formTypeId === Enum.FormType.CONTRACT_REBATE_SETTLEMENT) {
        if (pageTypeId === Enum.PageType.CONTRACT_REBATE_REIMBURSEMENT) {
          return RebateReimbursementApplyApp;
        } else if (pageTypeId === Enum.PageType.CONTRACT_REBATE_REIMBURSEMENT_ONINVOICE) {
          return OninvoiceReimbursementApplyApp;
        }
      } else if (formTypeId === Enum.FormType.CONTRACT_REBATE_PAYMENT) {
        if (pageTypeId === Enum.PageType.PAYMENT_CONTRACT_REBATE) {
          return RebatePaymentApplyApp;
        } else if (pageTypeId === Enum.PageType.PAYMENT_CONTRACT_ONINVOICE) {
          return OninvoicePaymentApplyApp;
        }
      } else if (formTypeId === Enum.FormType.ACTIVITY_PAYMENT) {
        return GeneralPaymentApplyApp;
      }
    }
  } else if (ModuleType.PoolPrepayReverse === moduleType) {
    if (mode === PageMode.Apply) {
      return PoolPrepayReverseApply;
    } else if (mode === PageMode.Approval) {
      return PoolPrepayReverseApproval;
    }
  }
  return Empty;
}

const getContainer = (key?: string) => {
  const id = String(key ? key : 'form-modal');
  let container = document.getElementById(id)
  if (!container) {
    container = document.createElement('div')
    container.setAttribute('id', id)
    document.body.appendChild(container)
  }
  return container;
}

const show = (props: FormPageProps) => {
  const {...newProps} = props;
  const container = getContainer(props.key);
  ReactDOM.render(<WdiPageDrawer {...newProps} />, container);
}

const api: PageControl = {
  show: (props: FormPageProps) => {
    const newProps = {...props, openMode: OpenMode.Drawer};
    if (!newProps.key) {
      newProps.key = Utility.guid();
    }
    show(newProps)
  },
  apply: (props: FormPageProps) => {
    const newProps = {mode: PageMode.Apply, moduleType: ModuleType.Form, openMode: OpenMode.Drawer, ...props};
    if (!newProps.key) {
      newProps.key = Utility.guid();
    }
    show(newProps)
  },
  approval: (props: FormPageProps) => {
    const newProps = {mode: PageMode.Approval, moduleType: ModuleType.Form, openMode: OpenMode.Drawer, ...props};
    if (newProps.moduleType == ModuleType.Form) {
      FormApi.doesAccess(props.data?.formId).then(response => {
        if (response.code == ResponseStatus.SUCCESS && response.data) {
          if (!newProps.key) {
            newProps.key = Utility.guid();
          }
          show(newProps)
        } else {
          message.error("没有权限查看单据");
        }
      })
    } else {
      if (!newProps.key) {
        newProps.key = Utility.guid();
      }
      show(newProps)
    }
  }
}

export default api;

export const WdiFormPageTitle: React.FC<any> = (props) => {
  const {title, moreConfig, approvalConfig, ...newProps} = props
  return (
    <div>
      <span>{title}</span>
      <WdiButtonContainer>
        <Space>
          <MoreWapper
            {...newProps}
            {...moreConfig}
          />
          <ApprovalButton
            {...newProps}
            {...approvalConfig}
          />
        </Space>
      </WdiButtonContainer>
    </div>
  );
}
