import { useEffect, useState } from 'react';
import { DetailGrid, Utility, wdiCore } from '@/components/GridWidget';
import { WdiCardTitle, WdiModalAppResult, WdiModalProps } from '@/components/WdiControls';
import { Button, Input, Space, message } from 'antd';
import { initFieldDisplayFormater, TableColumnRanderFormats } from '@/components/WdiBus/TableColumnRanderFormats';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import DataStore from '@/components/GridWidget/DataStore';
import RebateInfoSelectApp from './RebateInfoSelect';
import { ResponseStatus } from '@/utils/request';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { ImportModalApp } from '@/components/WdiControls/WdiImport';
import { Enum } from '@/enums/system';
import { GridApi } from '@/services/gridapi';
import { toExcel } from '@/components/WdiControls/WdiExcel';


const ModalAppTypeEnums = {
  None: 0,
  Insert: 1,
  Edit: 2,
  Import: 3,
  Select: 4,
};

export type DetailForApplyProps = {
  data?: any,
  dataSource: any[],
  onChange?: (dataSource: any) => void;
}

const RebateReimbursementDetailsForApplyPanel: React.FC<DetailForApplyProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [detailModalProps, setDetailModalProps] = useState<WdiModalProps>({ visible: false });
  const { data, dataSource, onChange } = props;

  const onDetailClose = () => {
    setDetailModalProps({ visible: false, title: "", type: ModalAppTypeEnums.None, id: null, data: null });
  };

  const onDetailSave = (result?: WdiModalAppResult) => {
    const rows = result?.data;
    let resultData: any[] = [];
    if (rows && rows.length > 0) {
      if ((rows.length + dataSource.length) > 1000) {
        message.error("明细数量过多请分开申请");
        return;
      }
      rows.forEach((item: any) => {
        if (dataSource.findIndex(row => row.rebateInfoId == item.rebateInfoId) < 0) {
          item.remark = null;
          resultData.push(item);
        }
      });
      if (resultData.length > 0) {
        onChange?.(DataStore.addMulti(dataSource, resultData));
      }
    }
    setDetailModalProps({ visible: false, title: "", type: ModalAppTypeEnums.None, id: null, data: null });
  };

  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const rows = uploadResult?.data?.data;
      if (uploadResult?.data?.exProperty) {
        if (rows && rows.length > 0) {
          onChange?.(DataStore.addMulti([], rows));
        }
      } else {
        let resultData = [...dataSource];
        if (rows && rows.length > 0) {
          rows.forEach((item: any) => {
            let record = resultData.find(row => row.rebateInfoId == item.rebateInfoId);
            if (record) {
              record.amount = wdiCore.Number.ToString(item?.amount, 2);
              record.remark = item?.remark;
              resultData = DataStore.edit(resultData, record);
            } else {
              resultData = DataStore.add(resultData, item);
            }
          });
          onChange?.(resultData);
        }
      }
    }
    setDetailModalProps({ visible: false, title: "", type: ModalAppTypeEnums.None, id: null, data: null });
  }

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormRsDetail', allowModifyColumns: true }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.amountSs.aggr = TableColumnRanderFormats.sumAggr('amountSs', 2);
      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);

      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.rebateInfoId.width = 100;
      settings.formSchema.allFields.speriod.width = 100;
      settings.formSchema.allFields.fperiod.width = 100;
      settings.formSchema.allFields.soldToCode.width = 200;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.amountSs.width = 100;
      settings.formSchema.allFields.amount.width = 150;

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  const maxAllowedFields = ["rowNumber", "rebateInfoId", "soldToCode", "custSysCode", "ecName", "productLineId", "speriod", "amountSs", "amount", "remark", "fperiod", "revSoldTo", "revenueTypeName", "paymentMethodName", "invoiceTypeName", "vatTypeName", "rateOriginal", "amountSa", "rateFinal", "settlementExplain", "bgName", "bohqName", "pageTypeName", "isConditional"];
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return maxAllowedFields;
    },
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true
    },
  };
  if (pageGridSettings && pageGridSettings.formSchema && pageGridSettings.formSchema.actions) {
    Utility.setActionCallback(
      pageGridSettings.formSchema.actions,
      'SystemDelete',
      async (grid: any, action: any, record: any) => {
        if (confirm("确定要删除该行吗？")) {
          onChange?.(DataStore.del(dataSource, record.uid));
          message.success("删除成功");
        }
      },
    );
  }

  const editColumn = {
    amount: (_t: any, record: any, index: any) => {
      return <WdiInputNumber format='d' digits={2} value={record.amount} onBlur={(e: any) => {
        const { ...newRecord } = record;
        newRecord.amount = wdiCore.Number.ToString(e.target.value, 2);
        onChange?.(DataStore.edit(dataSource, newRecord));
      }} />
    },
    remark: (_t: any, record: any, index: any) => {
      return <Input value={record.remark} onChange={(e: any) => {
        const { ...newRecord } = record;
        newRecord.remark = e.target.value;
        onChange?.(DataStore.edit(dataSource, newRecord));
      }} />;
    }
  };

  return (
    <>
      <WdiPanel
        title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='核销明细信息' />}
        extra={
          <>
            <Button type='primary' onClick={() => {
              const newDataSource = [...dataSource];
              newDataSource.forEach(item => {
                item.amount = wdiCore.Number.ToString(item.amountSs, 2);
              })
              onChange?.(newDataSource);
            }} style={{ float: 'right', marginRight: 10 }}>
              全部核销
            </Button>
            <Button
              type='primary'
              onClick={() => {
                setDetailModalProps({
                  visible: true, title: '选择合同返利单', type: ModalAppTypeEnums.Select, id: null, data: data
                });
              }}
              style={{ float: 'right', marginRight: 10 }}
            >选择返利单</Button>
            <Button type='primary' onClick={() => {
              setDetailModalProps({ title: '费用明细导入', visible: true, type: ModalAppTypeEnums.Import });
            }}
              style={{ float: 'right', marginRight: 10 }}>
              导入
            </Button>
          </>
        }
      >
        {isLoaded && detailModalProps.visible && detailModalProps.type == ModalAppTypeEnums.Select &&
          <RebateInfoSelectApp
            {...detailModalProps}
            onClose={onDetailClose}
            onSave={onDetailSave}
          />
        }
        {
          isLoaded &&
          detailModalProps.visible && detailModalProps.type == ModalAppTypeEnums.Import &&
          <ImportModalApp
            title={detailModalProps.title}
            visible={detailModalProps.visible}
            settingsUrl="/activityapi/rsButtons/getImportSettings"
            uploadingUrl="/activityapi/rsButtons/importUploading"
            onClose={onDetailClose}
            onSave={onImportSave}
            clientParameters={{
              pageTypeId: data.pageTypeId,
              details: JSON.stringify(dataSource),
            }}
          />
        }
        {isLoaded &&
          <DetailGrid {...pageGridSettings} params={data} editColumn={editColumn}
            autoColumn={['rowNumber']} />}
      </WdiPanel>
    </>
  );
}
export default RebateReimbursementDetailsForApplyPanel;

export const RebateReimbursementDetailsForApprovalPanel: React.FC<DetailForApplyProps> = (props) => {

  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormRsDetailForApproval', allowModifyColumns: true }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.None,
        showAggr: true,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.amountSs.aggr = TableColumnRanderFormats.sumAggr('amountSs', 2);
      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);

      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.rebateInfoId.width = 100;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.soldToCode.width = 200;
      settings.formSchema.allFields.speriod.width = 100;
      settings.formSchema.allFields.fperiod.width = 100;
      settings.formSchema.allFields.amountSs.width = 100;
      settings.formSchema.allFields.amount.width = 145;


      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);
  const maxAllowedFields = ["rowNumber", "rebateInfoId", "soldToCode", "custSysCode", "ecName", "productLineId", "speriod", "amountSs", "amount", "remark", "fperiod", "revSoldTo", "revenueTypeName", "paymentMethodName", "invoiceTypeName", "vatTypeName", "rateOriginal", "amountSa", "rateFinal", "settlementExplain", "bgName", "bohqName", "pageTypeName", "isConditional"];
  const { dataSource } = props;
  const pageGridSettings = Object.assign({}, gridSettings);

  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return maxAllowedFields;
    },
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true
    },
  };

  const exportDetails = () => {
    const columns = [
      { name: "rebateInfoId", displayName: "返利单编号" },
      { name: "soldToCode", displayName: "售达方编号" },
      { name: "soldToName", displayName: "售达方名称" },
      { name: "custSysCode", displayName: "客户集团编号" },
      { name: "custSysName", displayName: "客户集团名称" },
      { name: "ecName", displayName: "费用项" },
      { name: "productLineCode", displayName: "产品线编号" },
      { name: "productLineName", displayName: "产品线名称" },
      { name: "speriod", displayName: "销售月份" },
      { name: "amountSs", displayName: "结案金额" },
      { name: "amount", displayName: "核销金额" },
      { name: "remark", displayName: "核销备注" },
      { name: "fperiod", displayName: "预提月份" },
      { name: "revSoldTo", displayName: "当月销售额/销量" },
      { name: "revenueTypeName", displayName: "核算类型" },
      { name: "paymentMethodName", displayName: "支付方式" },
      { name: "invoiceTypeName", displayName: "发票类型" },
      { name: "vatTypeName", displayName: "税率类型" },
      { name: "rateOriginal", displayName: "初始比例/单位费用" },
      { name: "amountSa", displayName: "初始金额" },
      { name: "rateFinal", displayName: "结案比例/单位费用" },
      { name: "settlementExplain", displayName: "结案说明" },
      { name: "bgName", displayName: "预算组" },
      { name: "bohqName", displayName: "费用归属" },
      { name: "pageTypeName", displayName: "单据类型" },
      { name: "isConditional", displayName: "是否有条件", type: Enum.FieldType.BOOLEAN }
    ];

    const formSASheet = {
      data: dataSource,
      names: columns,
      sheetName: "返利核销明细",
      cells: {
        proQuantityRate: (fieldObject: any, row: any, value: any) => {
          if (value) {
            return wdiCore.Number.ToNumber(value * 100, 2);
          }
          return "";
        },
        proQuantityRate1: (fieldObject: any, row: any, value: any) => {
          if (value) {
            return wdiCore.Number.ToNumber(value * 100, 2);
          }
          return "";
        },
        proQuantityRate2: (fieldObject: any, row: any, value: any) => {
          if (value) {
            return wdiCore.Number.RateToString(value);
          }
          return "";
        }
      }
    };
    toExcel({
      name: "合同返利核销单_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
      sheetProps: [formSASheet]
    });
  };

  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用明细信息' />} collapsed={true} extra={
      <Space>
        <Button type='primary' onClick={(e) => { e.stopPropagation(); exportDetails(); }}>
          导出
        </Button>
      </Space>
    }>
      {isLoaded && <DetailGrid {...pageGridSettings} />}
    </WdiPanel>
  );
};
