import { Enum } from '@/enums/system';
import { formatter, request } from '@/utils/request';

const getApprovalUrl = (formTypeId: number) => {
  let url = '';
  if (formTypeId == Enum.FormType.ACTIVITY_APPLICATION) {
    url = '/activityapi/saButtons/approval';
  } else if (formTypeId == Enum.FormType.ACTIVITY_SETTLEMENT) {
    url = '/activityapi/ssButtons/approval';
  } else if (formTypeId == Enum.FormType.CONTRACT_REBATE_SETTLEMENT) {
    url = '/activityapi/rsButtons/approval';
  } else if (
    formTypeId == Enum.FormType.ACTIVITY_PAYMENT ||
    formTypeId == Enum.FormType.CONTRACT_REBATE_PAYMENT
  ) {
    url = '/activityapi/spButtons/approval';
  } else if (
    formTypeId == Enum.FormType.FORM_SALES_CONTRACT
  ) {
    url = '/contractapi/buttons/approval';
  }
  return url;
};

export namespace FormApi {
  export const Path = {
    Get: '/activityapi/form/get',
    GetByFormNo: '/activityapi/form/getByFormNo',
    GetFormWarningCount: '/activityapi/form/getFormWarningCount',
    QueryForMyDraft: '/reportapi/form/queryForMyDraft',
    SearchWaitForMyApprovalCount: '/reportapi/form/searchWaitForMyApprovalCount',
    QueryForWaitForMyApproval: '/reportapi/form/queryForWaitForMyApproval',
    SearchWaitForMyApprovalPaymentCount: '/reportapi/form/searchWaitForMyApprovalPaymentCount',
    QueryForWaitForMyApprovalPayment: '/reportapi/form/queryForWaitForMyApprovalPayment',
    SearchWaitForMyApprovalOtherCount: '/reportapi/form/searchWaitForMyApprovalOtherCount',
    QueryForWaitForMyApprovalOther: '/reportapi/form/queryForWaitForMyApprovalOther',
    QueryForMyApproved: '/reportapi/form/queryForMyApproved',
    QueryForMySubmit: '/reportapi/form/queryForMySubmit',
    DoesAccess: '/activityapi/form/doesAccess'
  }

  export async function getFormWarningCount(formId: Number) {
    const options = {
      method: 'GET',
      body: {
        formId: formId
      },
    };
    return request<API.ResponseResult<number>>(Path.GetFormWarningCount, formatter(options));
  }

  export async function get(id: Number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.Form>>(Path.Get, formatter(options));
  }
  export async function getByFormNo(id: string) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.Form>>(Path.GetByFormNo, formatter(options));
  }

  export async function doesAccess(formId?: Number) {
    const options = {
      method: 'GET',
      body: {
        formId: formId,
      },
    };
    return request<API.ResponseResult<Boolean>>(Path.DoesAccess, formatter(options));
  }

  export async function postApproval(body: API.ButtonArgs, formTypeId: number) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(getApprovalUrl(formTypeId), formatter(options));
  }

  export async function queryForMyDraft(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.FormQuery>>(Path.QueryForMyDraft, formatter(options));
  }
  export async function getWaitForMyApprovalCount() {
    const options = {
      method: 'POST',
      body: {

      },
    };
    return request<API.ResponseResult<number>>(Path.SearchWaitForMyApprovalCount, formatter(options));
  }
  export async function queryForWaitForMyApproval(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.FormQuery>>(Path.QueryForWaitForMyApproval, formatter(options));
  }
  export async function getWaitForMyApprovalOtherCount() {
    const options = {
      method: 'POST',
      body: {

      },
    };
    return request<API.ResponseResult<number>>(Path.SearchWaitForMyApprovalOtherCount, formatter(options));
  }
  export async function queryForWaitForMyApprovalOther(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.FormQuery>>(Path.QueryForWaitForMyApprovalOther, formatter(options));
  }
  export async function queryForMyApproved(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.FormQuery>>(Path.QueryForMyApproved, formatter(options));
  }
  export async function queryForMySubmit(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.FormQuery>>(Path.QueryForMySubmit, formatter(options));
  }
  export async function getWaitForMyApprovalPaymentCount() {
    const options = {
      method: 'POST',
      body: {

      },
    };
    return request<API.ResponseResult<number>>(Path.SearchWaitForMyApprovalPaymentCount, formatter(options));
  }
  export async function queryForWaitForMyApprovalPayment(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.FormQuery>>(Path.QueryForWaitForMyApprovalPayment, formatter(options));
  }

}
