import React, {useEffect, useRef, useState} from 'react';
import {Checkbox, Col, Form, message, Row, Tabs} from 'antd';
import ProForm, {ProFormInstance} from '@ant-design/pro-form';
import {BOHQ} from '@/services/mdm/bohq';
import {FileUploadUtility, WdiFileList} from '@/components/WdiControls/WdiFileUpload';
import {FormSalesContractTerms} from '@/services/contract/formsalescontractterms';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import DynamicInformation from '../../components/DynamicInformation';
import {FormPageProps, WdiFormPageTitle} from '@/components/WdiBus/FormPage';
import {initApprovalPageContext, ViewEntityProps} from '../../SalesContract/components/data';
import {ResponseStatus} from '@/utils/request';
import DataStore from '@/components/GridWidget/DataStore';
import {DataOptions} from '@/services/basic/metadata';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {WdiCardTitle, wdiCore, WdiInput, WdiStaffCard, WdiTextArea} from '@/components/WdiControls';
import {Enum} from '@/enums/system';
import {CustSys} from "@/services/mdm/custsys";
import {SoldTo} from "@/services/mdm/soldto";
import {SalesContractTermsForApprovalPanel} from '../../SalesContractApproval/components/details';

const moreConfig: any = {
  successParams: [
    {
      url: "/basicapi/favorite/getButtons",
      keys: []
    }, {
      url: "/contractapi/buttons/get",
      keys: [
        "UndoButton",
        "ReeditButton",
        "CancelButton",
        "CopyButton",
        "InterveneFlowButton"
      ]
    }
  ],
  errorParams: [
    {
      url: "/contractapi/buttons/get",
      keys: [
        "RepairButton",
        "InterveneFlowButton"
      ]
    }
  ]
};

const approvalConfig: any = {
  params: {
    url: "/contractapi/buttons/get",
    keys: [
      "ApprovalButton"
    ]
  }
};

const FormSalesContractApprovalApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [entity, setEntity] = useState<ViewEntityProps>();
  const useCaseId = Enum.UseCase.SALES_CONTRACT;

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApprovalPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;
        const {formSalesContract} = viewEntity;
        if (viewEntity.formSalesContract) {
          formRef.current?.setFieldsValue({
            contractDate: wdiCore.Date.Join(formSalesContract?.beginDate, formSalesContract?.endDate, "yyyyMM"),
            tpmContractCode: formSalesContract?.salesContractId ? formSalesContract?.tpmContractCode : formSalesContract?.tpmContractCode + '（合同未生成）',
            contractCode: formSalesContract?.contractCode,
            contractName: formSalesContract?.contractName,
            owner: formSalesContract?.owner,
            customerSignatory: formSalesContract?.customerSignatory,
            signDate: wdiCore.Date.ToString(formSalesContract?.signDate, "yyyy-MM-dd"),
            isAutoAppend: formSalesContract?.isAutoAppend,
            isOfficial: formSalesContract?.isOfficial ? '正式' : '计划',
            remark: formSalesContract?.remark,
            ttForTs: formSalesContract?.ttForTs,
            fileInfo: FileUploadUtility.parse(formSalesContract?.fileName, formSalesContract?.filePath),
            modifiedReason: viewEntity.formSalesContract.modifiedReason,
            revenueForecast: wdiCore.Number.AmountToString(formSalesContract?.revenueForecast),
            elnRateMax: wdiCore.Number.RateToString(formSalesContract?.elnRateMax, 4),
            expenseForecast: wdiCore.Number.AmountToString(formSalesContract?.expenseForecast),
            rateDealerMax: wdiCore.Number.RateToString(formSalesContract?.rateDealerMax, 4)
          });
        }
        setEntity(viewEntity);
        if (formSalesContract?.custSysCode) {
          CustSys.get(String(formSalesContract.custSysCode)).then((response) => {
            formRef.current?.setFieldsValue({
              custSysCode: response.data.codeOrName
            });
          });
        }
        if (formSalesContract?.soldToCode) {
          SoldTo.get(String(formSalesContract.soldToCode)).then((response) => {
            formRef.current?.setFieldsValue({
              soldToCode: response.data.codeOrName
            });
          });
        }
        if (formSalesContract?.bohqId) {
          BOHQ.get(formSalesContract.bohqId).then((response) => {
            formRef.current?.setFieldsValue({
              bohqName: response.data.bohqName
            });
          });
        }
        if (formSalesContract) {
          FormSalesContractTerms.getFormSalesContractTerms(formSalesContract.formSalesContractId).then((response) => {
            setDataSource(DataStore.init(response.data));
          });
        }
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `${entity.pageGroup?.pageGroupName}【${entity.form?.formNo}】，合同${entity.createOrModify == 2 ? '变更' : '创建'}`;
      DataOptions.search({typeGroup: 3, value: entity.form?.statusId}).then(response => {
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (response.data && response.data.length > 0) {
          title += `，${response.data[0].name}`;
          if (props.setPageTitle) props.setPageTitle(
            <WdiFormPageTitle
              title={title}
              onClose={props.onClose}
              callback={props.callback}
              form={entity?.form}
              useCaseId={useCaseId}
              moreConfig={moreConfig}
              approvalConfig={approvalConfig}/>
          );
        }
      });
    }
  }, [props.setPageTitle, entity]);

  return (
    <>
      <WdiStaffCard formId={entity?.form?.formId}/>
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab={<span>合同信息</span>} key="1">
          <ProForm
            formRef={formRef}
            submitter={{
              render: () => {
                return (<></>)
              },
            }}
          >
            <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.BASE_INFO} title='基本信息'/>} collapsed={true}>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={4}
                     hidden={entity?.form?.pageTypeId == Enum.PageType.DEALER_SALES_CONTRACT}>
                  <Form.Item name="custSysCode" label="客户集团">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="soldToCode" label="售达方">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="contractDate" label="合同期间">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="tpmContractCode" label="TPM合同编号">
                    <WdiInput readOnly style={entity?.formSalesContract?.salesContractId ? {
                      color: "#2299ee",
                      cursor: "pointer"
                    } : {}} onClick={() => {
                      if (entity?.formSalesContract?.salesContractId) {
                        window.open(`/contract/SalesContractApproval/?salesContractId=${entity?.formSalesContract?.salesContractId}`);
                      }
                    }}/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4} hidden={true}>
                  <Form.Item name="contractCode" label="合同编号">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4} hidden={true}>
                  <Form.Item name="contractName" label="合同名称">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4} hidden={true}>
                  <Form.Item name="owner" label="合同负责人">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4} hidden={true}>
                  <Form.Item name="customerSignatory" label="客户方签约人">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4} hidden={true}>
                  <Form.Item name="signDate" label="签约日期">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4} hidden={true}>
                  <Form.Item name="isAutoAppend" label="是否自动费用追溯" valuePropName="checked">
                    <Checkbox disabled></Checkbox>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="isOfficial" label="计划/正式合同">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="bohqName" label="费用归属">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={8}>
                  <Form.Item name="remark" label="合同备注">
                    <WdiTextArea rows={4} readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item name="ttForTs" label="需通过TP预提支付的合同费用">
                    <WdiTextArea rows={4} readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="fileInfo"
                    label="附件"
                    valuePropName='fileList'
                    getValueFromEvent={(e: any) => {
                      return e.fileList || [];
                    }}
                  >
                    <WdiFileList
                      fileList={FileUploadUtility.parse(entity?.formSalesContract?.fileName, entity?.formSalesContract?.filePath)}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={16}
                     hidden={entity?.createOrModify == 2 ? false : true}>
                  <Form.Item name="modifiedReason" label="修改原因">
                    <WdiTextArea rows={4} readOnly/>
                  </Form.Item>
                </Col>
              </Row>
            </WdiPanel>
            <WdiPanel
              collapsed={true}
              title={<WdiCardTitle iconType={Enum.IconType.LIMITED_INFO} title='合同费用预测'/>}
            >
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="revenueForecast" label="预估销售额(不含税)*">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="elnRateMax" label="ELN预估承担点数">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="expenseForecast" label="ELN预估费用">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="rateDealerMax" label="经销商承担点数"
                             hidden={entity?.form?.pageTypeId != Enum.PageType.INDIRECT_SALES_CONTRACT}>
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
              </Row>
            </WdiPanel>
          </ProForm>
          {entity?.form?.pageTypeId && (
            <SalesContractTermsForApprovalPanel
              data={{form: entity?.form, formSalesContract: entity?.formSalesContract}}
              pageTypeId={entity?.form?.pageTypeId} dataSource={dataSource}/>)}
        </TabPane>
        <TabPane tab={<span>动态信息</span>} key="3">
          <DynamicInformation
            formId={entity?.form?.formId}
            formTypeId={entity?.form?.formTypeId}
            pageTypeId={entity?.form?.pageTypeId}
            useCaseId={useCaseId}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
export default FormSalesContractApprovalApp;
