export namespace FormTypeEnum {
  export enum FormType {
    // 活动申请
    ACTIVITY_APPLICATION = 11,
    // 合同返利
    REBATE_INFO = 12,

    FORM_SALES_CONTRACT = 46,
    // 产品政策
    PRODUCT_POLICY = 13,
    // 冰柜返利
    FREEZER_REBATE = 14,
    // 广告类申请
    ACTIVITY_MKT_APPLICATION = 18,
    // 活动结案
    ACTIVITY_SETTLEMENT = 21,
    // 合同返利核销
    CONTRACT_REBATE_SETTLEMENT = 22,
    // 活动付款
    ACTIVITY_PAYMENT = 31,
    // 活动预付
    ACTIVITY_PRE_PAYMENT = 32,
    // 合同返利付款
    CONTRACT_REBATE_PAYMENT = 33,
    // 政策返利核销
    POLICY_REBATE_PAYMENT = 34,
    // 冰柜返利核销
    FREEZER_REBATE_PAYMENT = 35,
    // 广告类核销
    ACTIVITY_MKT_PAYMENT = 38,
    // 广告类预付
    ACTIVITY_MKT_PRE_PAYMENT = 39,
    // 专项项目
    PROJECT_APPLICATION = 41,
    // 客户合同
    SALES_CONTRACT = 46,
    // 外部费用
    EXPENSE_NON_TPM = 51,
    //费用池调整
    POOL_ADJUST = 61,
  }

  export enum PageGroup {
    // 后补类活动申请单
    ACTIVITY_APPLICATION = 1101,
    // 随货类活动申请单
    PRICE_DISCOUNT_APPLICATION = 1102,
    // 免费货类活动申请单
    FREE_GOODS_APPLICATION = 1103,
    // 经销商投资类活动申请
    DEALER_ACTIVITY_APPLICATION = 1104,
    // 经销商合同返利单
    CONTRACT_REBATE = 1201,
    // 间供客户合同返利单
    CONTRACT_REBATE_LEVEL_2 = 1202,
    // 直供客户合同返利单
    CONTRACT_REBATE_DIRECT = 1203,
    // 政策返利单(常规预提)
    POLICY_REBATE = 1301,
    // 政策返利单(发货预提)
    POLICY_REBATE_DELIVERY = 1302,
    // 政策返利单(君币核销)
    POLICY_REBATE_JUNBI = 1303,
    // 政策随货类
    POLICY_PRICE_DISCOUNT = 1304,
    // 冰柜返利单
    FREEZER_REBATE = 1401,
    // 广告类活动申请单
    MKT_APPLICATION = 1801,
    // 后补类活动结案单
    ACTIVITY_SETTLEMENT = 2101,
    // 后补类正常核销单
    ACTIVITY_PAYMENT = 3101,
    // 经销商投资类核销单
    DEALER_ACTIVITY_PAYMENT = 3102,
    // 后补类预付申请单
    ACTIVITY_PREPAYMENT = 3201,
    // 一级合同返利核销单
    PAYMENT_CONTRACT_REBATE = 3301,
    // on-invoice返点核销单
    PAYMENT_CONTRACT_ONINVOICE = 3302,
    // 政策返利核销单-常规预提
    PAYMENT_POLICY_REBATE = 3401,
    // 政策返利核销单-发货预提
    PAYMENT_POLICY_REBATE_DELIVERY = 3402,
    // 政策返利核销单-君币核销
    PAYMENT_POLICY_REBATE_JB = 3403,
    // 冰柜返利核销单
    PAYMENT_FREEZER_REBATE = 3501,
    // 广告类核销 - 正常核销
    PAYMENT_MKT = 3801,
    // 广告类核销 - 第三方支付冲销
    PAYMENT_MKT_PRE = 3802,
    // 广告类预付申请单
    ACTIVITY_MKY_PREPAYMENT = 3901,
    // 通用类专项申请单
    PROJECT_GENERAL = 4101,
    // 直供客户合同申请单
    DIRECT_SALES_CONTRACT = 4601,
    // 经销商合同申请单
    DEALER_SALES_CONTRACT = 4602,
    // 间供客户合同申请单
    INDIRECT_SALES_CONTRACT = 4603,
    // 预提手工调整
    EXPENSE_NON_TPM = 5103,
    // on-invoice合同返利
    CONTRACT_REBATE_ONINVOICE = 5104,
    // 广告类预提手工调整
    EXPENSE_MKY_NON_TPM = 5203,
    //费用调整申请单
    POOL_ADJUST_APPLICATION = 6101,
  }

  export enum PageType {
    // 活动申请单（客户投资类）
    ACTIVITY_APPLICATION = 1101,
    // 随货类活动申请单（本项目不用）
    PRICE_DISCOUNT_APPLICATION = 1102,
    // 免费货类活动申请单（本项目不用）
    FREE_GOODS_APPLICATION = 1103,
    // 活动申请单（经销商投资类）
    ACTIVITY_APPLICATION_DEALER = 1104,

    // 经销商合同返利单
    CONTRACT_REBATE = 1201,

    // 间供客户合同返利单
    CONTRACT_REBATE_LEVEL_2 = 1202,

    // 直供客户合同返利单
    CONTRACT_REBATE_DIRECT = 1203,

    // 广告类活动申请
    MKT_APPLICATION = 1801,
    // 活动核销单：客户投资类
    ACTIVITY_SETTLEMENT = 2101,
    //活动核销单：经销商投资类
    ACTIVITY_SETTLEMENT_DEALER = 2104,

    // 合同返利核销单
    CONTRACT_REBATE_REIMBURSEMENT = 2201,
    // on-invoce合同返利核销
    CONTRACT_REBATE_REIMBURSEMENT_ONINVOICE = 2202,

    // 客户投资类核销单
    PAYMENT_CUST_SYS = 3101,

    // 经销商投资类核销单
    PAYMENT_SOLD_TO = 3102,

    // 后补类核销 - 第三方支付冲销
    PAYMENT_THIRD_PART_PRE = 3104,
    // 后补类核销 - 票扣
    PAYMENT_BILL_DEDUCT = 3105,
    // 后补类核销 - 货补
    PAYMENT_FREE_GOODS = 3107,
    // 后补类核销-直营货补
    PAYMENT_FREE_GOODS_KA = 3108,

    // 后补类核销-通用
    PAYMENT_COMMON = 3111,
    // 后补类核销-费用池
    PAYMENT_TO_POOL = 3112,

    // 后补类预付申请单
    PRE_PAYMENT_THIRD_PART = 3201,
    // 合同返利付款单
    PAYMENT_CONTRACT_REBATE = 3301,
    // on-invoice合同返利付款单
    PAYMENT_CONTRACT_ONINVOICE = 3302,
    // 通用类专项申请单
    PROJECT_GENERAL = 4101,
    // 直供客户合同申请单
    DIRECT_SALES_CONTRACT = 4601,
    // 经销商合同申请单
    DEALER_SALES_CONTRACT = 4602,
    // 间供客户合同申请单
    INDIRECT_SALES_CONTRACT = 4603,
    //费用调整申请单
    POOL_ADJUST_APPLICATION = 6101,
  }
}
