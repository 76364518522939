import {WdiCardTitle, wdiCore} from '@/components/WdiControls';
import {DetailForApplyProps} from './details';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {WdiTable} from "@/components/WdiControls/WdiTable";
import {Enum} from '@/enums/system';
import {FormApi} from "@/services/sales";
import {ResponseStatus} from "@/utils/request";

const SalesContractHistoryForPanel: React.FC<DetailForApplyProps> = (props) => {
  const showDispModal = (evt: any) => {
    if (evt && evt.target) {
      const dataKey = evt.target.getAttribute("data-key");

      FormApi.get(dataKey).then(response => {
        if (response.code === ResponseStatus.SUCCESS) {
          const form = response.data;
          window.open(`/contract/FormSalesContractApproval/?formId=${form.formId}&pageTypeId=${form.pageTypeId}&formTypeId=${form.formTypeId}`);
        }
      });
    }
  };
  const {dataSource} = props;
  const columns = [
    {
      title: '申请单编号',
      key: 'formNo',
      dataIndex: 'formNo',
      width: 110,
      render: (_: any, record: any) => (
        <a onClick={showDispModal} data-key={record.formId}>
          {record.formNo}
        </a>
      )
    },
    {
      title: '申请日期',
      key: 'submitDate',
      dataIndex: 'submitDate',
      width: 100,
      render: (value: any) => <span>{wdiCore.Date.DateToString(value)}</span>,
    },
    {
      title: '申请人',
      key: 'staffName',
      dataIndex: 'staffName',
      width: 100
    },
    {
      title: '申请单状态',
      key: 'statusName',
      dataIndex: 'statusName',
      width: 100,
    },
    {
      title: '修改原因',
      key: 'modifiedReason',
      dataIndex: 'modifiedReason'
    },
  ];
  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.HISTORY} title='申请和修改历史'/>} collapsed={true}>
      <WdiTable rowKey={'formId'} columns={columns} dataSource={dataSource} pagination={false}/>
    </WdiPanel>
  );
};

export default SalesContractHistoryForPanel;
